import React from "react";
import type { GadgetFlags } from "state-trees/src/GadgetFlags";
import type { DocsArea } from "./DocsInterface";
import { DocsVersions, type FullMetaBlob, type ShortMetaBlob } from "./FullMetaBlob";
import type { GadgetItemProps } from "./nav/navigation-types";

export const currentDocsArea = (path: string): DocsArea => {
  path = path.split("#")[0];
  path = path.split("?")[0];

  for (const version of DocsVersions) {
    if (version.prefix != "") {
      if (path.startsWith(version.prefix)) {
        path = path.replace(version.prefix, "");
        break;
      }
    }
  }

  return path === "" || path === "/" || path.startsWith("/guides")
    ? "guides"
    : /^(\/env\/\w+)?\/api\//.test(path)
    ? "api"
    : path.startsWith("/reference")
    ? "reference"
    : path.startsWith("/assistant")
    ? "assistant"
    : "notFound";
};

export const buildApiReferencePrefix = (currentApp: FullMetaBlob) => {
  const currentSlug = currentApp.environment.slug;
  return `/api/${currentApp.slug}/${currentSlug}`;
};

export interface IDocsContext extends IDocsParams {
  docsArea: DocsArea;
  topLevelItems: GadgetItemProps[];
  showTsLanguage: boolean;
  setShowTsLanguage: React.Dispatch<React.SetStateAction<boolean>>;
  initializedTranspiler: boolean;
}

export interface DocsUser {
  id: string;
  email: string;
  name: string;
  isStaff: boolean;
}

export interface IDocsParams {
  currentApp: FullMetaBlob;
  availableApps: ShortMetaBlob[];
  currentUser?: DocsUser;
  GadgetFlags: GadgetFlags;
}

export const DocsContext = React.createContext<IDocsContext>(null as any);
export const useDocsContext = () => React.useContext(DocsContext);
