import { StyledSvg } from "baseui/icon";
import React from "react";

export const ModelsDocIcon = (props: React.ComponentProps<typeof StyledSvg>) => {
  return (
    <StyledSvg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M48 6C46.7941 6 45.5814 6.31271 44.4961 6.94141L13.5 24.8359C11.3352 26.0844 10 28.4032 10 30.8984V65.1016C10 67.5973 11.3373 69.9168 13.5 71.1641L44.4961 89.0586C45.5814 89.6873 46.7941 90 48 90C49.2059 90 50.4186 89.6873 51.5039 89.0586L82.5 71.1641C84.6648 69.9156 86 67.5968 86 65.1016V30.8984C86 28.4027 84.6627 26.0832 82.5 24.8359L51.5039 6.94141C50.4186 6.31271 49.2059 6 48 6ZM48 12C48.1741 12 48.3454 12.0455 48.4961 12.1328H48.5L76.8594 28.5039L48.5 44.8789C48.185 45.0606 47.815 45.0606 47.5 44.8789L19.1406 28.5039L47.5 12.1328H47.5039C47.6545 12.0455 47.8259 12 48 12ZM16 33.6172L44.5 50.0742C44.6623 50.1678 44.8323 50.2408 45 50.3203V82.4219L16.5 65.9688C16.1867 65.788 16 65.4658 16 65.1016V33.6172ZM80 33.6172V65.1016C80 65.4623 79.8112 65.7893 79.5 65.9688L51 82.4219V50.3203C51.1677 50.2408 51.3377 50.1678 51.5 50.0742L80 33.6172Z"
        fill="#545454"
      />
    </StyledSvg>
  );
};
