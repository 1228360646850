import { borderShadow, Button, Row } from "@gadgetinc/widgets";
import { CheckmarkIcon } from "@gadgetinc/widgets/src/icons/CheckmarkIcon";
import { ChevronDownIcon } from "@gadgetinc/widgets/src/icons/ChevronDownIcon";
import { useStyletron } from "baseui";
import { NestedMenus, StatefulMenu } from "baseui/menu";
import { PLACEMENT, StatefulPopover } from "baseui/popover";
import { toaster } from "baseui/toast";
import { LabelSmall } from "baseui/typography";
import { sortBy } from "lodash";
import React, { useCallback, useContext, useMemo, useState } from "react";
import useFetch from "use-http";
import { safelyRunAsyncEventHandler } from "web/src/lib/utils";
import { DocsContext } from "./DocsContext";
import { useDocsLocation } from "./nav/useDocsLocation";

export const AppSelector = () => {
  const { currentApp, availableApps, currentUser } = useContext(DocsContext);
  const { loading, post: setCurrentApplication } = useFetch("/api/set-current-app");
  const [css, $theme] = useStyletron();
  const [currentSlug, setCurrentSlug] = useState(currentApp.slug);
  const [location] = useDocsLocation();
  const [isOpen, setIsOpen] = useState(false);

  const options = useMemo(
    () =>
      sortBy(
        (availableApps || []).map((app) => ({
          id: app.slug,
          label: (
            <Row>
              <LabelSmall className={css({ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" })}>{app.slug}</LabelSmall>
              {app.slug === currentApp.slug && <CheckmarkIcon style={{ marginLeft: "auto", minWidth: "16px" }} />}
            </Row>
          ),
        })),
        "label"
      ),
    [availableApps]
  );

  const selectApp = useCallback(
    (app: { id: string; label: string }) => {
      safelyRunAsyncEventHandler(async () => {
        try {
          const slug = String(app.id);

          setCurrentSlug(slug);
          await setCurrentApplication({ slug });

          const url = new URL(
            location,
            // url throws if a valid base url isn't passed to the constructor. we don't actually use the protocol or host in this case
            typeof window == "undefined"
              ? process.env.NODE_ENV == "production"
                ? "https://docs.gadget.dev/"
                : "https://docs.ggt.dev/"
              : window.location.href
          );

          // Take user to home of current docs area (or docs Home if none), because they may be on a page that won't exist if they change apps
          // We also need to do a page load to trigger reloading the `currentApp` into the context
          url.pathname = url.pathname.replace(/\/api\/.*/, `/api/${slug}`); // let the route select the first development environment by default

          // if we've used a query param to specify the app to load let's clear it so that we use the session app instead
          if (url.searchParams.has("slug")) {
            url.searchParams.delete("slug");
          }

          window.location.hash = "";
          window.location.href = `${url.pathname}${url.search}`;
        } catch (error) {
          toaster.negative("There was an error changing applications, please try again", { autoHideDuration: 3000 });
        }
      });
    },
    [location]
  );

  if (loading || !currentUser) {
    return null;
  }

  return (
    <Row>
      <AppSelectorDivider />
      <StatefulPopover
        placement={PLACEMENT.bottomLeft}
        stateReducer={(stateChangeType, nextState) => {
          setIsOpen(nextState.isOpen);
          return nextState;
        }}
        content={({ close }) => (
          <NestedMenus>
            <StatefulMenu
              items={options}
              overrides={{ List: { style: { maxHeight: "300px", overflowY: "auto", width: "262px" } } }}
              onItemSelect={({ item }) => selectApp(item)}
            />
          </NestedMenus>
        )}
      >
        <Button
          kind="secondary"
          $pressed={isOpen}
          $style={{
            minWidth: "50px",
            maxWidth: "180px",
            backgroundColor: isOpen ? `${$theme.colors.primary200} !important` : "transparent",
            boxShadow: isOpen ? borderShadow($theme.colors.primary200, 1, $theme.lighting.shadowButtonInset) : "none",
            ":hover": { backgroundColor: $theme.colors.primary100 },
            ":active:not(:disabled)": { backgroundColor: $theme.colors.primary200 },

            paddingTop: $theme.sizing.scale100,
            paddingBottom: $theme.sizing.scale100,
            paddingLeft: $theme.sizing.scale200,
            paddingRight: $theme.sizing.scale200,
          }}
        >
          <LabelSmall className={css({ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", marginRight: "4px" })}>
            {currentSlug}
          </LabelSmall>
          <ChevronDownIcon style={{ marginLeft: "auto", minWidth: "16px" }} />
        </Button>
      </StatefulPopover>
    </Row>
  );
};

const AppSelectorDivider = () => {
  const [css, $theme] = useStyletron();

  return (
    <div
      className={css({
        width: "1px",
        height: $theme.sizing.scale600,
        backgroundColor: $theme.colors.primary300,
        marginLeft: $theme.sizing.scale600,
        marginRight: $theme.sizing.scale600,
      })}
    />
  );
};
