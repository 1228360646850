import type { EnvironmentSlug, LegacyEnvironmentName } from "state-trees/src/Environment";

export const buildGraphQLPlaygroundURL = (endpoint: URL | string, query: string, variables: any, environment: LegacyEnvironmentName) => {
  const params = new URLSearchParams({ query, variables: JSON.stringify(variables, null, 2), environment });

  if (typeof endpoint === "string") {
    return `${endpoint}?${params.toString()}`;
  } else {
    endpoint.search = params.toString();
    return endpoint.toString();
  }
};

export const buildJavaScriptPlaygroundURL = (primaryDomain: string, code: string, environment: LegacyEnvironmentName | EnvironmentSlug) => {
  const params = new URLSearchParams({ code, environment });
  const url = new URL("api/playground/javascript", primaryDomain);
  url.search = params.toString();
  return url.toString();
};
