import { format } from "prettier";
import parserBabel from "prettier/parser-babel";

type PreservedSyntax = { original: string; replacement: string };

// A list of syntaxes that we need to preserve in the code so that esbuild will keep them.
const preservedSyntaxes: PreservedSyntax[] = [
  // esbuild strips out all comments, we need to replace them with legal comments and esbuild will keep them.
  // Reference: https://esbuild.github.io/api/#legal-comments
  { original: "// ", replacement: "//!@comment " },
  { original: "/**", replacement: "/*!@comment " },
  // esbuild renames `params` used in the action context to `params2` to avoid name collisions (they think), so we need to replace it.
  // Reference: https://github.com/evanw/esbuild/issues/1027
  { original: "export const params", replacement: "export_const_params" },
  // esbuild renames `process.env.NODE_ENV` to `development`.
  { original: "NODE_ENV", replacement: "NODE_ENV_KEEP" },
];

/**
 * A transpiler runner that uses esbuild to transpile the code.
 * Make sure to manually initialize the esbuild-wasm instance before using this class if you want to use wasm mode.
 */
export class EsbuildTranspiler {
  getEsbuildOptions(code: string) {
    return {
      stdin: {
        contents: code,
        loader: "tsx",
        resolveDir: ".",
      },
      write: false,
      jsx: "preserve",
      legalComments: "inline",
      minify: false,
      target: "es2022",
      tsconfigRaw: {
        compilerOptions: {
          verbatimModuleSyntax: true,
        },
      },
    } as const;
  }

  addTypeComment(code: string, targetSyntax: string, typeName: string) {
    const typeComment = `@type { ${typeName} }`;
    const newCommentBlock = `/** ${typeComment} */\n`;

    const modifiedCode = code.replace(
      // Look for either an existing comment block immediately above `run` or `export const run` directly
      new RegExp(`(\\/\\*\\*[^]*?\\*\\/\\s*)?(${targetSyntax})`),
      (match, commentBlock, exportLine) => {
        if (commentBlock) {
          // If there is an existing comment block, check if it already has `@type { ActionRun }`
          if (commentBlock.includes(`@type { ${typeName} }`)) return match;

          // Insert `@type { typeName }` before the end of the existing comment block
          const modifiedCommentBlock = commentBlock.replace(/(\s*\*\/)/, `\n * ${typeComment}$1`);
          return modifiedCommentBlock + exportLine;
        } else {
          // If no comment block exists, prepend a new comment block
          return newCommentBlock + exportLine;
        }
      }
    );

    return modifiedCode;
  }

  async transpile(
    code: string,
    wasmMode: boolean,
    options?: {
      addActionTypeComments?: boolean;
      addRouteTypeComment?: boolean;
    }
  ) {
    let modifiedCode = code;

    if (options?.addActionTypeComments) {
      modifiedCode = this.addTypeComment(modifiedCode, "export const run: ActionRun = async", "ActionRun");
      modifiedCode = this.addTypeComment(modifiedCode, "export const onSuccess: ActionOnSuccess = async", "ActionOnSuccess");
      modifiedCode = this.addTypeComment(modifiedCode, "export const options: ActionOptions = {", "ActionOptions");
    }

    if (options?.addRouteTypeComment) {
      modifiedCode = this.addTypeComment(modifiedCode, "const route: RouteHandler = async", "RouteHandler");
    }

    modifiedCode = preservedSyntaxes
      .reduce((code, { original, replacement }) => code.replaceAll(original, replacement), modifiedCode)
      .replaceAll("\n\n", "//!@newline\n");

    const esbuildTranspile = wasmMode ? (await import("esbuild-wasm")).build : (await import("esbuild")).build;

    const result = await esbuildTranspile(this.getEsbuildOptions(modifiedCode));
    const transpiledCode = result.outputFiles[0].text;

    const modifiedTranspiledCode = preservedSyntaxes
      .reduce((code, { original, replacement }) => code.replaceAll(replacement, original), transpiledCode)
      .replaceAll("//!@newline\n", "\n")
      .replaceAll("/* @__PURE__ */ ", "")
      // esbuild will convert numbers with more than 3 zeros to scientific notation, we need to convert them back to a normal number.
      .replace(/\b(\d+e[+-]?\d+)\b/g, (match) => {
        return Number(match).toString();
      })
      .replaceAll("void 0", "undefined");

    return modifiedTranspiledCode;
  }

  prettify(code: string) {
    return format(code, {
      parser: "babel",
      plugins: [parserBabel],
      printWidth: 80,
    }).trimEnd();
  }
}
