import React, { useContext } from "react";
import { convertTsToJsFileName } from "state-trees/src/file-based-actions/filePathUtils";
import { InlineCode } from "web/src/components/markdown/Typography";
import { DocsContext } from "../chrome/DocsContext";

export const renameTsToJs = (filename: string, showTsLanguage: boolean) => (showTsLanguage ? filename : convertTsToJsFileName(filename));

export const TsFilePath = (props: { filename: string }) => {
  const { showTsLanguage } = useContext(DocsContext);
  const filename = renameTsToJs(props.filename, showTsLanguage);
  return <>{filename}</>;
};

export const InlineTsFilePath = (props: { filename: string }) => {
  return (
    <InlineCode>
      <TsFilePath {...props} />
    </InlineCode>
  );
};
