import type { ButtonProps } from "baseui/button";
import { Button, SIZE } from "baseui/button";
import { omit } from "lodash";
import * as React from "react";
import type { LegacyEnvironmentName } from "state-trees/src/Environment";
import stripComments from "strip-comments";
import { buildGraphQLPlaygroundURL, buildJavaScriptPlaygroundURL } from "web/src/lib/playground";

export const OpenInGraphQLPlaygroundButton = (
  props: ButtonProps & { endpoint: string; graphql: string; variables: any; tabName: string; environment: LegacyEnvironmentName }
) => {
  return (
    <Button
      size={SIZE.compact}
      onClick={() => {
        const playgroundURL = buildGraphQLPlaygroundURL(
          new URL("playground", props.endpoint.endsWith("/") ? props.endpoint : `${props.endpoint}/`),
          props.graphql,
          props.variables,
          props.environment
        );
        window.open(playgroundURL, "_docsPlayground");
      }}
      overrides={{
        BaseButton: {
          style: ({ $theme }) => ({
            ...props.$style,
            ...$theme.typography.MonoLabelXSmall,
            backgroundColor: "rgba(255,255,255,0.1)",
            ":hover": {
              backgroundColor: "rgba(255,255,255,0.2)",
            },
          }),
        },
      }}
      {...omit(props, ["endpoint", "graphql", "variables", "tabName", "environment"])}
    >
      Open in playground
    </Button>
  );
};

export const OpenInJavaScriptPlaygroundButton = (
  props: ButtonProps & { code: string; primaryDomain: string; tabName: string; environment: LegacyEnvironmentName }
) => {
  return (
    <Button
      size={SIZE.compact}
      onClick={() => {
        const playgroundURL = buildJavaScriptPlaygroundURL(
          props.primaryDomain,
          props.code ? stripComments(props.code) : "",
          props.environment
        );
        window.open(playgroundURL, "_docsPlayground");
      }}
      overrides={{
        BaseButton: {
          style: ({ $theme }) => ({
            ...props.$style,
            ...$theme.typography.MonoLabelXSmall,
            backgroundColor: "rgba(255,255,255,0.1)",
            ":hover": {
              backgroundColor: "rgba(255,255,255,0.2)",
            },
          }),
        },
      }}
      {...omit(props, ["code", "primaryDomain", "tabName", "environment"])}
    >
      Open in playground
    </Button>
  );
};
