import { expandBorderColors, expandPadding } from "@gadgetinc/widgets";
import { DocsErrorIcon } from "@gadgetinc/widgets/src/icons/DocsErrorIcon";
import { DocsInfoIcon } from "@gadgetinc/widgets/src/icons/DocsInfoIcon";
import { DocsLightbulbIcon } from "@gadgetinc/widgets/src/icons/DocsLightbulbIcon";
import { DocsWarningIcon } from "@gadgetinc/widgets/src/icons/DocsWarningIcon";
import { styled, useStyletron } from "baseui";
import type { ButtonOverrides } from "baseui/button";
import type { StyledSvg } from "baseui/icon";
import type { Theme } from "baseui/theme";
import { merge, omit } from "lodash";
import type { ReactNode } from "react";
import React, { createContext, useContext } from "react";
import type { StyleObject } from "styletron-react";
import type { DocsButtonProps } from "../components/DocsButton";
import { DocsButton } from "../components/DocsButton";

export interface CalloutProps {
  title?: ReactNode;
  type?: "info" | "warning" | "negative";
  iconType?: "info" | "alert" | "lightbulb";
  children: React.ReactNode;
  $style?: StyleObject;
}

const semanticStyles = ($theme: Theme, $type: CalloutProps["type"]) => {
  switch ($type) {
    case "warning": {
      return {
        backgroundColor: $theme.colors.warning50,
        iconColor: $theme.colors.warning500,
        boxShadow: `0px 2px 0px ${$theme.colors.warning400}`,
        border: `1.5px solid ${$theme.colors.warning400}`,
      };
    }
    case "negative": {
      return {
        backgroundColor: $theme.colors.negative50,
        iconColor: $theme.colors.negative500,
        boxShadow: `0px 2px 0px ${$theme.colors.negative400}`,
        border: `1.5px solid ${$theme.colors.negative400}`,
      };
    }
    case "info":
    default: {
      return {
        backgroundColor: $theme.colors.primary50,
        iconColor: $theme.colors.primary500,
        boxShadow: `0px 2px 0px ${$theme.colors.primary400}`,
        border: `1.5px solid ${$theme.colors.primary400}`,
      };
    }
  }
};

const CalloutWrapper = styled<{ $type: CalloutProps["type"] }, "div", Theme>(
  "div",
  ({ $theme, $type }: { $type: CalloutProps["type"]; $theme: Theme }) => {
    const colors = semanticStyles($theme, $type);
    return {
      ...colors,
      ...expandPadding($theme.sizing.scale600),
      paddingBottom: 0,
      paddingLeft: $theme.sizing.scale1200,
      borderRadius: $theme.sizing.scale100,
      marginBottom: $theme.sizing.scale800,
      position: "relative",
      height: "fit-content",
    };
  }
);

const CalloutIcon = (props: { calloutProps: CalloutProps } & React.ComponentProps<typeof StyledSvg>): JSX.Element => {
  const iconProps = omit(props, "calloutProps");
  switch (props.calloutProps.type) {
    case "warning":
      return <DocsWarningIcon {...iconProps} />;
    case "negative":
      return <DocsErrorIcon {...iconProps} />;
    case "info":
    default: {
      if (props.calloutProps.iconType === "lightbulb") {
        return <DocsLightbulbIcon {...iconProps} />;
      }
      return <DocsInfoIcon {...iconProps} />;
    }
  }
};

const CalloutContext = createContext<CalloutProps["type"]>("info");

export const Callout = (props: CalloutProps) => {
  const [css, $theme] = useStyletron();
  const { type = "info", iconType = "info" } = props;

  return (
    <CalloutContext.Provider value={type}>
      <CalloutWrapper $type={type} $style={props.$style}>
        <CalloutIcon
          calloutProps={{ type, iconType }}
          $size={20}
          $style={{
            position: "absolute",
            top: props.title ? "16px" : "20px",
            left: "16px",
            display: "block",
            height: $theme.sizing.scale700,
            width: $theme.sizing.scale700,
            flexShrink: 0,
            alignSelf: "flex-start",
          }}
        />
        <div className={css({ display: "flex", flexDirection: "column" })}>
          {props.title && (
            <div className={css({ ...$theme.typography.HeadingXSmall, marginBottom: $theme.sizing.scale400 })}>{props.title}</div>
          )}
          <div className={css({ ...$theme.typography.ParagraphSmall })}>{props.children}</div>
        </div>
      </CalloutWrapper>
    </CalloutContext.Provider>
  );
};

export const CalloutButton = (props: DocsButtonProps) => {
  const [css, $theme] = useStyletron();

  const contextType = useContext(CalloutContext);
  const overrides: ButtonOverrides = {};
  if (contextType == "warning") {
    merge(overrides, {
      BaseButton: {
        style: {
          backgroundColor: $theme.colors.white,
          color: $theme.colors.contentPrimary,
          ...expandBorderColors($theme.colors.warning300),
          boxShadow: $theme.lighting.shadow400,
          ":hover": {
            backgroundColor: $theme.colors.primary50,
          },
          ":focus": {
            outlineColor: $theme.colors.warning400,
          },
        },
      },
    });
  }

  return <DocsButton {...props} overrides={overrides} />;
};
