import { isExcludedNamespaceSegment, version1PathPreferences } from "@gadgetinc/conventions";
import { isReadOnlyNode, types } from "@gadgetinc/mobx-quick-tree";
import { last } from "lodash";
import path from "path";
import { FileSetCache } from "./FileSetCache";
import type { ModelIndexEntry, SourceFileIndexEntry } from "./IndexEntry";
import { schemaMetadataFileName } from "./database/schemaMetadataFileName";
import {
  fileIsNamespacedModelSchemaMetadata,
  getFolderPathEndingWithSlash,
  globalActionsDir,
  isExecutableJsOrTsFile,
  modelsDir,
  schemaFileDirParts,
} from "./file-based-actions/filePathUtils";
import { getAppSettings } from "./utils";

/**
 * Return a list of namespace segments based on a subset of the folder path.
 */
export const getNamespace = (folderPath: string) => {
  const segments = folderPath.split("/");
  return segments.filter((segment) => !isExcludedNamespaceSegment(segment));
};

const recursivelyGetNamespacedModelId = (schemaPaths: string[], candidateSegments: string[]): string | undefined => {
  if (candidateSegments.length === 0) return;
  const candidateSchemaPath = path.join("api/models", ...candidateSegments, schemaMetadataFileName);

  if (schemaPaths.includes(candidateSchemaPath)) {
    return candidateSegments.join("/");
  } else {
    return recursivelyGetNamespacedModelId(schemaPaths, candidateSegments.slice(0, -1));
  }
};

export const FilesystemIntrospector = types
  .model({})
  .views((self) => ({
    get filesWithoutProblems(): SourceFileIndexEntry[] {
      throw new Error("Not implemented - This will come from the corresponding index");
    },
    get modelsWithoutProblemsData(): ModelIndexEntry[] {
      throw new Error("Not implemented - This will come from the corresponding index");
    },
    get canHaveNamespacedModels() {
      return getAppSettings(self).frameworkFeatures.supportNamespace;
    },
  }))
  .volatile((self) => ({
    filesets: new FileSetCache(
      () => self.filesWithoutProblems,
      () => self.canHaveNamespacedModels,
      isReadOnlyNode(self),
      () => self.modelsWithoutProblemsData
    ),
  }))
  .views((self) => ({
    maybeGetModelNamespacedIdByFilePath(filePath: string) {
      const paths = filePath.split("/");

      if (!(paths[0] === "api" && paths[1] === "models")) {
        return;
      }

      const schemaPaths = self.filesets.schemaFilePaths;
      const candidateSegments = paths.slice(2).filter((segment) => segment !== "");

      const namespacedApiIdentifier = recursivelyGetNamespacedModelId(schemaPaths, candidateSegments);

      if (!namespacedApiIdentifier) {
        return;
      }
      return namespacedApiIdentifier;
    },
  }))
  .views((self) => ({
    maybeGetModelIdByFilePath(filePath: string) {
      const namespacedApiIdentifier = self.maybeGetModelNamespacedIdByFilePath(filePath);
      const modelApiIdentifier = last(namespacedApiIdentifier?.split("/"));

      if (!modelApiIdentifier) {
        return;
      }
      return modelApiIdentifier;
    },
    getSchemaFileByModelNamespacedId(namespacedApiIdentifier: string) {
      const schemaPaths = self.filesets.schemaFilePaths;
      const foundNamespacedApiIdentifier = recursivelyGetNamespacedModelId(schemaPaths, namespacedApiIdentifier.split("/"));
      if (!foundNamespacedApiIdentifier) return;
      return `api/models/${foundNamespacedApiIdentifier}/${schemaMetadataFileName}`;
    },
    maybeGetModelNamespaceBySchemaFile(filePath: string) {
      if (!fileIsNamespacedModelSchemaMetadata({ path: filePath })) {
        return [];
      }

      const dirNameParts = schemaFileDirParts(filePath);

      if (dirNameParts && dirNameParts.length > 1) {
        // Ex: 'api/models/a/b/c/schema.gadget.ts' -> ['a', 'b']
        return getNamespace(dirNameParts.slice(0, -1).join("/"));
      } else {
        // Empty namespace - Ex: 'api/models/modelA/schema.gadget.ts'
        return [];
      }
    },
  }))
  .views((self) => ({
    maybeGetModelNamespaceByFilePath(filePath: string) {
      const namespacedApiIdentifier = self.maybeGetModelNamespacedIdByFilePath(filePath);
      if (!namespacedApiIdentifier) return [];

      const schemaFile = self.getSchemaFileByModelNamespacedId(namespacedApiIdentifier);
      if (!schemaFile) return [];

      return self.maybeGetModelNamespaceBySchemaFile(schemaFile);
    },

    maybeGetGlobalActionNamespaceByFilePath(filePath: string, opts?: { fileMustExist?: boolean }) {
      if (opts?.fileMustExist && !self.filesets.globalActionFilePaths.includes(filePath)) {
        throw new Error(`Cannot get global action namespace for file ${filePath} because it is not a global action file`);
      }

      if (!self.canHaveNamespacedModels) return [];

      const regex = /api\/actions\/([^/]+(?:\/[^/]+)*)\/[^/]+\.[^/]+$/;
      const match = filePath.match(regex);

      if (match && match[1]) {
        return getNamespace(match[1]);
      } else {
        return [];
      }
    },

    isPathAModelFolder(filePath: string) {
      const candidateSchemaPath = path.join(filePath, "schema.gadget.ts");
      return self.filesets.schemaFilePaths.includes(candidateSchemaPath);
    },

    isPathInAModelFolder(filePath: string) {
      return !!self.maybeGetModelIdByFilePath(filePath);
    },
  }))
  .views((self) => ({
    isPathAModelNamespaceFolder(folderPath: string) {
      const folderPathWithEndingSlash = getFolderPathEndingWithSlash(folderPath);
      return (
        !self.isPathAModelFolder(folderPath) &&
        folderPathWithEndingSlash !== modelsDir &&
        self.filesets.schemaFilePaths.some((path) => path.startsWith(folderPathWithEndingSlash))
      );
    },
    isPathAGlobalActionNamespaceFolder(folderPath: string) {
      const folderPathWithEndingSlash = getFolderPathEndingWithSlash(folderPath);
      return (
        folderPathWithEndingSlash !== globalActionsDir &&
        self.filesets.globalActionFilePaths.some((path) => path.startsWith(folderPathWithEndingSlash))
      );
    },
    maybeGetModelFolderPathByFilePath(filePath: string) {
      if (!filePath.startsWith("api/models/")) return;
      const modelNamespacedId = self.maybeGetModelNamespacedIdByFilePath(filePath);
      if (!modelNamespacedId) return;
      let modelFolderPath = self.getSchemaFileByModelNamespacedId(modelNamespacedId);
      modelFolderPath = modelFolderPath?.replace(`/${schemaMetadataFileName}`, "");
      return modelFolderPath;
    },
  }))
  .views((self) => ({
    isPathModelActionsFolder(path: string) {
      // Optional ending '/'
      const namespaceAllowedModelActionFolder = /^api\/models\/(.+\/)+actions\/$/;
      const nonNamespacedModelActionFolder = /^api\/models\/(?![0-9/]+)([^/]+)\/actions\/$/;

      const modelActionFolderRegex = self.canHaveNamespacedModels ? namespaceAllowedModelActionFolder : nonNamespacedModelActionFolder;
      return modelActionFolderRegex.test(path);
    },
    isPathInModelActionsFolder(path: string) {
      const modelApiId = self.maybeGetModelIdByFilePath(path);
      const namespace = self.maybeGetModelNamespaceByFilePath(path);
      if (!modelApiId || !namespace) {
        return false;
      }

      const namespaceAllowedModelActionFolder = /^api\/models\/(.+\/)+actions\/([^/]+)$/;
      const nonNamespacedModelActionFolder = /^api\/models\/([^/]+)\/actions\/([^/]+)$/; // Cannot be subfolder in `api/models/modelApiId/actions/`
      const inModelActionFolderRegex = self.canHaveNamespacedModels ? namespaceAllowedModelActionFolder : nonNamespacedModelActionFolder;

      return inModelActionFolderRegex.test(path) && path.startsWith(`${self.maybeGetModelFolderPathByFilePath(path)}/actions`);
    },
  }))
  .views((self) => ({
    maybeGetModelActionNamespaceByFilePath(filePath: string) {
      if (!self.isPathInModelActionsFolder(filePath) || !self.canHaveNamespacedModels) return [];

      const regex = /actions\/([^/]+(?:\/[^/]+)*)\/[^/]+\.[^/]+$/;
      const match = filePath.match(regex);

      if (match && match[1]) {
        return getNamespace(match[1]);
      } else {
        return [];
      }
    },
  }))
  .views((self) => ({
    /**
     * Check if a file is a model schema metadata file. Note that this allows for namespaced models.
     */
    fileIsModelSchemaMetadata(file: { path: string }) {
      return fileIsNamespacedModelSchemaMetadata(file);
    },

    maybeGetModelActionIdByFilePath(filePath: string) {
      if (!isExecutableJsOrTsFile(filePath)) {
        return;
      }

      if (!(self.isPathInAModelFolder(filePath) && self.isPathInModelActionsFolder(filePath))) {
        return;
      }

      const actionApiIdentifier = path.basename(filePath, path.extname(filePath));

      return actionApiIdentifier;
    },

    getModelFolderByNamespacedId(namespacedApiIdentifier: string) {
      const schemaFilePath = self.getSchemaFileByModelNamespacedId(namespacedApiIdentifier);
      if (!schemaFilePath) return;
      return path.dirname(schemaFilePath);
    },

    maybeGetGlobalActionIdByFilePath(filePath: string, opts?: { fileMustExist?: boolean }) {
      if (opts?.fileMustExist && !self.filesets.globalActionFilePaths.includes(filePath)) {
        throw new Error(`Cannot get global action api id for file ${filePath} because it is not a global action file`);
      }

      if (filePath.startsWith(version1PathPreferences.globalActionsFolderPath) && isExecutableJsOrTsFile(filePath)) {
        return path.basename(filePath, path.extname(filePath));
      }
    },
  }))

  .views((self) => ({
    isSchemaFileNestedUnderAnotherModel(checkedPath: string) {
      if (!self.fileIsModelSchemaMetadata({ path: checkedPath })) {
        return false;
      }

      return self.filesets.schemaFilePaths.some(
        (schemaFilePath) => schemaFilePath !== checkedPath && checkedPath.startsWith(`${path.dirname(schemaFilePath)}/`)
      );
    },
  }));
