import { StyledSvg } from "baseui/icon";
import React from "react";

export const GraphQLAPIPlaygroundIcon = (props: React.ComponentProps<typeof StyledSvg>) => (
  <StyledSvg viewBox="0 0 30 30" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.7446 21.565H26.385L25.935 20.7741V8.55253L15.4588 2.36198L14.9975 1.55127L14.5362 2.36198L4.05998 8.55253V20.7741L3.60999 21.565H4.25035L14.9975 27.9156L25.7446 21.565ZM24.685 18.5772V9.26503L16.7027 4.5483L24.685 18.5772ZM13.2922 4.54829L5.30998 9.26503V18.5772L13.2922 4.54829ZM6.70614 21.565H23.2888L14.9975 26.4644L6.70614 21.565ZM14.9975 4.07877L24.235 20.315H5.75999L14.9975 4.07877Z"
    />
    <path
      d="M14.9975 5.315C16.2056 5.315 17.185 4.33562 17.185 3.1275C17.185 1.91938 16.2056 0.940002 14.9975 0.940002C13.7894 0.940002 12.81 1.91938 12.81 3.1275C12.81 4.33562 13.7894 5.315 14.9975 5.315Z"
      strokeWidth="0.2"
    />
    <path
      d="M14.9975 29.065C16.2056 29.065 17.185 28.0856 17.185 26.8775C17.185 25.6694 16.2056 24.69 14.9975 24.69C13.7894 24.69 12.81 25.6694 12.81 26.8775C12.81 28.0856 13.7894 29.065 14.9975 29.065Z"
      strokeWidth="0.2"
    />
    <path
      d="M4.9975 22.815C6.20562 22.815 7.185 21.8356 7.185 20.6275C7.185 19.4194 6.20562 18.44 4.9975 18.44C3.78937 18.44 2.81 19.4194 2.81 20.6275C2.81 21.8356 3.78937 22.815 4.9975 22.815Z"
      strokeWidth="0.2"
    />
    <path
      d="M24.9975 22.815C26.2056 22.815 27.185 21.8356 27.185 20.6275C27.185 19.4194 26.2056 18.44 24.9975 18.44C23.7894 18.44 22.81 19.4194 22.81 20.6275C22.81 21.8356 23.7894 22.815 24.9975 22.815Z"
      strokeWidth="0.2"
    />
    <path
      d="M4.9975 11.565C6.20562 11.565 7.185 10.5856 7.185 9.3775C7.185 8.16938 6.20562 7.19 4.9975 7.19C3.78937 7.19 2.81 8.16938 2.81 9.3775C2.81 10.5856 3.78937 11.565 4.9975 11.565Z"
      strokeWidth="0.2"
    />
    <path
      d="M24.9975 11.565C26.2056 11.565 27.185 10.5856 27.185 9.3775C27.185 8.16938 26.2056 7.19 24.9975 7.19C23.7894 7.19 22.81 8.16938 22.81 9.3775C22.81 10.5856 23.7894 11.565 24.9975 11.565Z"
      strokeWidth="0.2"
    />
  </StyledSvg>
);
