import { StyledSvg } from "baseui/icon";
import React from "react";

export const LongerArrowIcon = (props: React.ComponentProps<typeof StyledSvg> & { direction: "up" | "down" | "left" | "right" }) => {
  let rotation = 0;
  if (props.direction == "down") {
    rotation = 90;
  } else if (props.direction == "left") {
    rotation = 180;
  } else if (props.direction == "up") {
    rotation = -90;
  }

  return (
    <StyledSvg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.3269 5.99353C15.1943 5.99357 15.0647 6.03315 14.9546 6.10723C14.8446 6.1813 14.7592 6.2865 14.7093 6.40938C14.6594 6.53225 14.6473 6.66722 14.6745 6.79702C14.7017 6.92682 14.767 7.04556 14.862 7.13806L17.0573 9.33337H1.33339C1.24505 9.33212 1.15734 9.34844 1.07536 9.38139C0.993381 9.41433 0.918767 9.46324 0.855853 9.52527C0.79294 9.5873 0.742983 9.66121 0.708885 9.74272C0.674786 9.82422 0.657227 9.91169 0.657227 10C0.657227 10.0884 0.674786 10.1759 0.708885 10.2574C0.742983 10.3389 0.79294 10.4128 0.855853 10.4748C0.918767 10.5368 0.993381 10.5858 1.07536 10.6187C1.15734 10.6516 1.24505 10.668 1.33339 10.6667H17.0573L14.862 12.862C14.798 12.9234 14.747 12.997 14.7118 13.0784C14.6766 13.1599 14.658 13.2475 14.6571 13.3362C14.6562 13.4249 14.673 13.5129 14.7065 13.595C14.74 13.6771 14.7896 13.7517 14.8523 13.8144C14.9151 13.8771 14.9897 13.9267 15.0718 13.9602C15.1539 13.9938 15.2419 14.0106 15.3306 14.0097C15.4193 14.0088 15.5069 13.9902 15.5883 13.955C15.6697 13.9198 15.7433 13.8687 15.8047 13.8047L19.0795 10.53C19.1609 10.4679 19.2269 10.388 19.2725 10.2963C19.318 10.2046 19.3418 10.1037 19.3421 10.0013C19.3423 9.89897 19.319 9.79792 19.2739 9.70602C19.2288 9.61412 19.1632 9.53384 19.0821 9.47139C19.0808 9.47052 19.0795 9.46965 19.0782 9.46879L15.8047 6.19535C15.7426 6.13149 15.6683 6.08073 15.5862 6.04606C15.5042 6.0114 15.416 5.99354 15.3269 5.99353Z"
        transform={`rotate(${rotation} 10 10)`}
      />
    </StyledSvg>
  );
};
