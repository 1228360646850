import { useStyletron } from "baseui";
import { StyledSvg } from "baseui/icon";
import React from "react";

export const DocsErrorIcon = (props: React.ComponentProps<typeof StyledSvg>) => {
  const [_css, $theme] = useStyletron();
  return (
    <StyledSvg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.99992 2C9.44554 2 8.97063 2.33782 8.76945 2.81901L1.53377 15.2982V15.2995C1.40319 15.5098 1.33375 15.7524 1.33325 16C1.33325 16.3536 1.47373 16.6928 1.72378 16.9428C1.97382 17.1929 2.31296 17.3333 2.66659 17.3333C2.69787 17.3331 2.72914 17.3318 2.76034 17.3294L2.76294 17.3333H9.99992H17.2369L17.2395 17.3281C17.2707 17.331 17.3019 17.3327 17.3333 17.3333C17.6869 17.3333 18.026 17.1929 18.2761 16.9428C18.5261 16.6928 18.6666 16.3536 18.6666 16C18.6663 15.752 18.5969 15.5089 18.4661 15.2982L18.4556 15.2799C18.4552 15.2795 18.4548 15.2791 18.4543 15.2786L11.2304 2.81901C11.0292 2.33782 10.5543 2 9.99992 2ZM9.19132 7.57292H10.8085L10.6744 11.888H9.32544L9.19132 7.57292ZM10.0025 13.207C10.5505 13.207 10.8788 13.5019 10.8788 14.0052C10.8788 14.4992 10.5505 14.793 10.0025 14.793C9.45052 14.793 9.11971 14.4992 9.11971 14.0052C9.11971 13.5019 9.44986 13.207 10.0025 13.207Z"
        fill={$theme.colors.negative500}
      />
    </StyledSvg>
  );
};
