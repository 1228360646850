import { pickBy } from "lodash";
export const backendFolder = "api/";
export const modelsFolderSegment = "models/";
export const globalActionsFolderSegment = "actions/";

export const PermissionsLocation = "accessControl/permissions";

export const modelsFolderPath = `${backendFolder}${modelsFolderSegment}`;
export const globalActionsFolderPath = `${backendFolder}${globalActionsFolderSegment}`;

export const isExcludedNamespaceSegment = (segment: string) => {
  return segment[0] === "(" && segment[segment.length - 1] === ")";
};

export type PathPreferenceSpec = {
  backendFolder: string;
  frontendFolder: string;
  frontendRoutesFolder: string;
  modelsFolderPath: string;
  globalActionsFolderPath: string;
  routesPath: string;
  bootPluginsPath: string;
  computedViewsPath: string;
  backendRouteFileRegex: RegExp;
  getActionFilePath: (modelId: string, actionId: string, typescript?: boolean) => string;
  getActionFolderPath: (namespacedModelApiId: string) => string;
  getModelFilterFolder: (modelId: string) => string;
  getBackendRouteFilePath(routeFilename: string): string;
  getBootPluginFilePath(pluginFilename: string): string;
  getModelSchemaFilePath(modelId: string, namespace: string[]): string;
};

export const version1PathPreferences: PathPreferenceSpec = {
  backendFolder,
  frontendFolder: "web/",
  frontendRoutesFolder: "web/routes/",
  modelsFolderPath,
  globalActionsFolderPath,
  routesPath: "api/routes/",
  bootPluginsPath: "api/boot/",
  computedViewsPath: "api/views/",
  backendRouteFileRegex: /^\/?api\/routes\/((\/|[^[:*]*)\/)?GET(-?([^:[*]*)(\..+)?)?\.(js|ts)$/,
  getActionFilePath: (modelId: string, actionId: string, typescript?: boolean) => {
    return `${modelsFolderPath}${modelId}/actions/${actionId}.${typescript ? "ts" : "js"}`;
  },
  getActionFolderPath: (namespacedModelApiI: string) => {
    return `${modelsFolderPath}${namespacedModelApiI}/actions/`;
  },
  getModelFilterFolder: (modelId: string) => {
    return `accessControl/filters/${modelId}`;
  },
  getBackendRouteFilePath(routeFilename: string) {
    return `${this.routesPath}${routeFilename}`;
  },
  getBootPluginFilePath(pluginFilename: string) {
    return `${this.bootPluginsPath}${pluginFilename}`;
  },
  getModelSchemaFilePath(modelId: string, namespace: string[]) {
    return `${modelsFolderPath}${[...namespace, modelId].join("/")}/schema.gadget.ts`;
  },
};

export const aacPathPreferences: PathPreferenceSpec = {
  backendFolder: "",
  frontendFolder: "frontend/",
  frontendRoutesFolder: "frontend/routes/",
  modelsFolderPath: "",
  globalActionsFolderPath: "globalActions/",
  routesPath: `routes/`,
  bootPluginsPath: `boot/`,
  computedViewsPath: `views/`,
  backendRouteFileRegex: /^\/?routes\/((\/|[^[:*]*)\/)?GET(-?([^:[*]*)(\..+)?)?\.(js|ts)$/,
  getActionFilePath: (modelId: string, actionId: string, typescript?: boolean) => {
    return `${modelId}/actions/${actionId}.${typescript ? "ts" : "js"}`;
  },
  getActionFolderPath: (modelId: string) => {
    return `${modelId}/actions/`;
  },
  getModelFilterFolder: (modelId: string) => {
    return `accessControl/filters/${modelId}`;
  },
  getBackendRouteFilePath(routeFilename: string) {
    return `${this.routesPath}${routeFilename}`;
  },
  getBootPluginFilePath(pluginFilename: string) {
    return `${this.bootPluginsPath}${pluginFilename}`;
  },
  getModelSchemaFilePath(modelId: string, _namespace: string[]) {
    return `${modelsFolderPath}${modelId}/schema.gadget.ts`;
  },
};

export const getPathPreferences = (flags: { fileBasedActions: boolean }) => {
  return flags.fileBasedActions ? version1PathPreferences : aacPathPreferences;
};

export type ModelTransitionState =
  | Record<
      string,
      {
        from?: Record<string, string> | string;
        to: Record<string, string> | string;
      }
    >
  | undefined;

export const supportedViteConfigPaths = [
  "vite.config.js",
  "vite.config.ts",
  "vite.config.mjs",
  "vite.config.mts",
  "frontend/vite.config.js", // Legacy frontend beta apps
  "web/vite.config.js", // Legacy frontend beta apps that are upgraded to v1.0
] as const;

export const maybeFindViteConfigFilename = async (
  finder: (viteConfigPath: (typeof supportedViteConfigPaths)[number]) => Promise<boolean>
) => {
  for (const viteConfigPath of supportedViteConfigPaths) {
    if (await finder(viteConfigPath)) {
      return viteConfigPath;
    }
  }
};

export const isLegacyViteConfigLocation = (viteConfigPath: (typeof supportedViteConfigPaths)[number]) => {
  return viteConfigPath === "web/vite.config.js" || viteConfigPath === "frontend/vite.config.js";
};

export const UNKNOWN_SHOPIFY_APP_URL = "https://not-known-at-build-time.gadget.app";

export const getFrontendEnvVariables = (
  platformVariables: {
    appSlug: string;
    environmentSlug: string;
    shopifyAppUrl?: string;
  },
  userVariables: Record<string, string | null>
) => {
  const publicEnvVariables = pickBy(userVariables, (_value, key) => {
    return key.toLowerCase().startsWith("gadget_public");
  });

  return {
    ...publicEnvVariables,
    GADGET_APP: platformVariables.appSlug,
    GADGET_ENV: platformVariables.environmentSlug,
    GADGET_PUBLIC_APP_SLUG: platformVariables.appSlug,
    GADGET_PUBLIC_APP_ENV: platformVariables.environmentSlug,
    GADGET_PUBLIC_SHOPIFY_APP_URL: platformVariables.shopifyAppUrl,
  };
};

/**
 * Where a log message originates from -- Gadget's platform code, or a user's code like an action or route
 */
export enum LogSource {
  Platform = "platform",
  User = "user",
  Command = "command",
}
