import { StyledSvg } from "baseui/icon";
import React from "react";

export const ActionsDocIcon = (props: React.ComponentProps<typeof StyledSvg>) => {
  return (
    <StyledSvg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M55.3427 5.00144C54.8869 5.01586 54.4395 5.12903 54.0306 5.33332C53.6218 5.53761 53.261 5.82826 52.9726 6.18565L14.7191 53.4871C14.3376 53.9595 14.0964 54.5318 14.0234 55.1373C13.9505 55.7429 14.0488 56.357 14.3069 56.9084C14.565 57.4597 14.9724 57.9257 15.4818 58.2522C15.9912 58.5787 16.5817 58.7524 17.1847 58.753H37.374V87.7789C37.3749 88.4436 37.5787 89.0917 37.9576 89.6347C38.3365 90.1777 38.8719 90.589 39.4907 90.8124C40.1095 91.0358 40.7813 91.0603 41.4144 90.8826C42.0474 90.7049 42.6108 90.3337 43.0274 89.8198L81.2809 42.5184C81.6624 42.0459 81.9036 41.4737 81.9766 40.8681C82.0495 40.2625 81.9512 39.6484 81.6931 39.0971C81.435 38.5457 81.0276 38.0797 80.5182 37.7532C80.0088 37.4267 79.4183 37.2531 78.8153 37.2524H58.626V8.22653C58.6261 7.79477 58.5406 7.36737 58.3744 6.96967C58.2083 6.57197 57.9649 6.21207 57.6587 5.91131C57.3525 5.61054 56.9897 5.37503 56.5919 5.21874C56.1941 5.06244 55.7693 4.98855 55.3427 5.00144ZM52.2504 17.2593V40.4775C52.2505 41.3328 52.5864 42.1531 53.1842 42.7579C53.782 43.3627 54.5928 43.7025 55.4382 43.7026H72.0911L43.7496 78.7461V55.5279C43.7495 54.6726 43.4136 53.8524 42.8158 53.2475C42.218 52.6427 41.4072 52.3029 40.5618 52.3028H23.9089L52.2504 17.2593Z"
        fill="#545454"
      />
    </StyledSvg>
  );
};
