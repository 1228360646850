import { StyledTableBodyRow } from "baseui/table-semantic";
import React from "react";

export const TableRow = ({ children }: { children: React.ReactNode }) => {
  return (
    <StyledTableBodyRow
      // disable on hover background color
      $style={{
        ":hover": {
          backgroundColor: "transparent",
        },
      }}
    >
      {children}
    </StyledTableBodyRow>
  );
};
