import { StyledSvg } from "baseui/icon";
import React from "react";

export const JavaScriptIcon = (props: React.ComponentProps<typeof StyledSvg>) => (
  <StyledSvg viewBox="0 0 16 16" {...props}>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.68893 9.73949C10.096 10.4007 10.5383 11.0342 11.4754 11.0342C12.2625 11.0342 12.6754 10.643 12.6754 10.1018C12.6754 9.4542 12.2484 9.2242 11.3825 8.84713L10.9078 8.64478C9.53775 8.0636 8.62657 7.33536 8.62657 5.79712C8.62657 4.37947 9.71187 3.30064 11.4078 3.30064C12.6154 3.30064 13.4831 3.71887 14.109 4.81417L12.6301 5.75947C12.3048 5.17829 11.9531 4.94947 11.4078 4.94947C10.8513 4.94947 10.4989 5.30065 10.4989 5.75947C10.4989 6.32653 10.8519 6.55595 11.6666 6.90712L12.1413 7.10948C13.756 7.79713 14.6666 8.49949 14.6666 10.0783C14.6666 11.7795 13.3231 12.7119 11.5195 12.7119C9.7554 12.7119 8.75363 11.8266 8.19598 10.7307L9.68893 9.73949ZM2.8736 9.78773C3.17125 10.3207 3.62361 10.7307 4.2742 10.7307C4.89655 10.7307 5.25479 10.4848 5.25479 9.5289V3.30005H7.21539V9.83008C7.21539 11.8107 6.06656 12.7119 4.3889 12.7119C2.87302 12.7119 1.77889 11.6848 1.33301 10.7307L2.8736 9.78773Z"
        fill="#B4980A"
      />
    </svg>
  </StyledSvg>
);
