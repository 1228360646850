import { DISCORD_INVITE_URL, Row, expandBaseWebBorder, expandBorderBottom, paddingHorizontal, paddingVertical } from "@gadgetinc/widgets";
import { SidebarNavBreakpoint, ThreeColumnNavBreakpoint, WideSidebarNavBreakpoint } from "@gadgetinc/widgets/src/DocsTheme";
import { ExternalLinkIcon } from "@gadgetinc/widgets/src/icons/ExternalLinkIcon";
import { useStyletron } from "baseui";
import type { ReactNode, RefObject } from "react";
import React, { useContext } from "react";
import { StyleObject } from "styletron-react";
import { DocLink } from "../../markdown-components";
import { AppSelector } from "../AppSelector";
import { DocsContext } from "../DocsContext";
import { DocsSearchBar } from "../search/DocsSearchBar";
import { DocsAreaLogoType } from "./DocsAreaLogoType";
import { LargeSidebarWidth, SmallSidebarWidth } from "./DocsLeftNav";
import { DocsNavDrawer } from "./DocsNavDrawer";
import { DocsUserMenu } from "./DocsUserMenu";
import type { GadgetItemProps } from "./navigation-types";
import { navItemIsActive } from "./navigation-types";
import { useDocsLocation } from "./useDocsLocation";

export const DocsTopBar = (props: { mainContainerRef: RefObject<any> }) => {
  const [css, $theme] = useStyletron();
  const { currentApp, topLevelItems } = useContext(DocsContext);

  return (
    <nav
      data-testid="docs-top-bar"
      className={css({
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        position: "sticky",
        top: 0,
        zIndex: 2,
        backgroundColor: $theme.colors.primary50,
        ...paddingVertical($theme.sizing.scale200),
        ...expandBorderBottom($theme.borders.border300),
        boxShadow: $theme.lighting.depth2,
      })}
    >
      <Row
        $style={{
          marginRight: "auto",
          flex: 1,
          ...paddingHorizontal($theme.sizing.scale400),
          [`@media screen and ${SidebarNavBreakpoint}`]: {
            width: SmallSidebarWidth,
          },
          [`@media screen and ${WideSidebarNavBreakpoint}`]: {
            width: LargeSidebarWidth,
          },
        }}
        $gap={$theme.sizing.scale200}
      >
        <div
          className={css({
            flexGrow: 0,
            display: "block",
            [`@media screen and ${SidebarNavBreakpoint}`]: {
              display: "none",
            },
          })}
        >
          <DocsNavDrawer />
        </div>
        <DocsAreaLogoType />
        <AppSelector />
      </Row>
      <Row
        $style={{
          flexDirection: "row",
          gap: $theme.sizing.scale500,
          flex: 1,
          justifyContent: "center",
          ...paddingHorizontal($theme.sizing.scale400),
          display: "none",
          [`@media screen and ${SidebarNavBreakpoint}`]: {
            display: "flex",
          },
        }}
      >
        {topLevelItems.map((item) => (
          <NavBarSectionLink key={item.itemId} item={item}>
            {item.title}
          </NavBarSectionLink>
        ))}
      </Row>
      <Row
        $style={{
          marginRight: $theme.sizing.scale400,
          marginLeft: "auto",
          justifyContent: "end",
          flex: 1,
          [`@media screen and ${SidebarNavBreakpoint}`]: {
            width: "370px",
          },
          [`@media screen and ${ThreeColumnNavBreakpoint}`]: {
            marginRight: $theme.sizing.scale800,
          },
        }}
        $gap={$theme.sizing.scale200}
      >
        <DocsSearchBar currentApp={currentApp} modalMountNode={props.mainContainerRef.current} />
        <DocsUserMenu />
        <Row
          $style={{
            display: "none",
            [`@media screen and ${SidebarNavBreakpoint}`]: {
              display: "flex",
            },
          }}
        >
          <div
            className={css({
              marginLeft: $theme.sizing.scale200,
              marginRight: $theme.sizing.scale0,
              borderLeftStyle: "solid",
              borderLeftWidth: "1px",
              borderLeftColor: $theme.colors.primary300,
              height: "16px",
              width: "1px",
              content: "''",
            })}
          ></div>
          <NavBarLink href={DISCORD_INVITE_URL} $style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            Discord <ExternalLinkIcon $size={16} />
          </NavBarLink>
        </Row>
      </Row>
    </nav>
  );
};

const NavBarSectionLink = (props: { item: GadgetItemProps; children: ReactNode }) => {
  const [location] = useDocsLocation();
  const selected = navItemIsActive(props.item, location);

  return (
    <NavBarLink href={props.item.itemId} selected={selected}>
      {props.children}
    </NavBarLink>
  );
};

const NavBarLink = (props: { href: string; selected?: boolean; children: ReactNode; $style?: StyleObject }) => {
  const [css, $theme] = useStyletron();

  return (
    <DocLink
      href={props.href}
      $style={{
        ...$theme.typography.LabelSmall,
        ...paddingHorizontal($theme.sizing.scale500),
        ...paddingVertical($theme.sizing.scale200),
        borderRadius: $theme.borders.radius200,
        color: props.selected ? $theme.colors.contentPrimary : $theme.colors.primary700,
        fontWeight: props.selected ? 500 : 400,
        backgroundColor: props.selected ? $theme.colors.primary200 : "transparent",
        boxShadow: props.selected ? "0px 1px 2px 0px rgba(0, 0, 0, 0.08) inset" : "none",
        ...(props.selected ? expandBaseWebBorder($theme.borders.border200) : {}),
        whiteSpace: "nowrap",
        ":hover": {
          color: $theme.colors.contentPrimary,
          backgroundColor: props.selected ? $theme.colors.primary200 : $theme.colors.backgroundTertiary,
        },
        ":visited": {
          color: "auto",
        },
        ...props.$style,
      }}
    >
      {props.children}
    </DocLink>
  );
};
