import { useCallback } from "react";
import { useLocation, useRouter } from "wouter";
import type { GadgetItemProps } from "./navigation-types";

/**
 * React hook for getting a function to navigate to a given url
 * Useful because it's aware of the router base path, and navigates to external urls properly
 */
export const useDocsLocation = (): [string, (item: GadgetItemProps | string) => void] => {
  const router = useRouter();
  const [location, setLocation] = useLocation();

  return [
    location,
    useCallback(
      (item: GadgetItemProps | string) => {
        const requestedPath = typeof item === "string" ? item : item.itemId;
        if (requestedPath.startsWith("http")) {
          window.location.href = requestedPath;
        } else {
          setLocation(requestedPath.replace(router.base, ""));
        }
      },
      [router.base, setLocation]
    ),
  ];
};
