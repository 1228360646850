import { GraphQLAPIPlaygroundIcon } from "@gadgetinc/widgets/src/icons/GraphQLAPIPlaygroundIcon";
import { JavaScriptIcon } from "@gadgetinc/widgets/src/icons/JavaScriptIcon";
import { ReactIcon } from "@gadgetinc/widgets/src/icons/ReactIcon";
import { TypeScriptIcon } from "@gadgetinc/widgets/src/icons/TypeScriptIcon";
import { useStyletron } from "baseui";
import React from "react";

export const languageLabel = (language: string) => {
  const key = language.toLowerCase();

  switch (key) {
    case "js":
    case "javascript": {
      return "JavaScript";
    }
    case "ts":
    case "typescript": {
      return "TypeScript";
    }
    case "jsx":
    case "react": {
      return "React";
    }
    case "tsx": {
      return "React (TypeScript)";
    }
    case "graphql": {
      return "GraphQL";
    }
    case "shell":
    case "shell-session": {
      return "Shell";
    }
    default: {
      return language;
    }
  }
};

export const LanguageIcon = (props: { language: string }) => {
  if (props.language === "javascript" || props.language === "js") {
    return <JavaScriptIcon />;
  } else if (props.language === "typescript" || props.language === "ts") {
    return <TypeScriptIcon />;
  } else if (props.language === "react" || props.language === "tsx") {
    return <ReactIcon />;
  } else if (props.language === "graphql") {
    return <GraphQLAPIPlaygroundIcon />;
  } else {
    return null;
  }
};

export const LanguageIndicator = (props: { children: React.ReactNode; color: string; onClick?: () => void }) => {
  const [css, $theme] = useStyletron();

  return (
    <div
      className={css({
        ...$theme.typography.MonoLabelXSmall,
        color: props.color,
        cursor: "pointer",
      })}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};
