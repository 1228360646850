import { StyledTableBodyCell } from "baseui/table-semantic";
import { ListItem, OrderedList, UnorderedList } from "web/src/components/markdown/ListItems";
import { Table, THead, TBody } from "web/src/components/markdown/Table";
import { Paragraph, InlineCode, Blockquote } from "web/src/components/markdown/Typography";
import { CodeBlock } from "./CodeBlock";
import { DocLink } from "./DocLink";
import { H1, H2, H3, H4, H5, H6 } from "./Headings";
import { TableHeadCell } from "./TableHeadCell";
import { TableRow } from "./TableRow";

export const MarkdownComponents = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
  li: ListItem,
  p: Paragraph,
  ol: OrderedList,
  ul: UnorderedList,
  inlineCode: InlineCode,
  blockquote: Blockquote,
  a: DocLink,
  code: CodeBlock,
  table: Table,
  thead: THead,
  tbody: TBody,
  tr: TableRow,
  td: StyledTableBodyCell,
  th: TableHeadCell,
};
