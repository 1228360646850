import { styled } from "baseui";
import type { BlockProps } from "baseui/block";
import * as React from "react";

const Wrapper = styled<{ $isVisible: boolean }, "a">("a", ({ $isVisible, $theme }) => ({
  marginTop: $theme.sizing.scale100,
  visibility: $isVisible ? "visible" : "hidden",
  color: $theme.colors.primary,
  ":focus": {
    outline: `3px solid ${$theme.colors.accent}`,
    outlineOffset: "1px",
  },
}));

const elementToSize = (element: BlockProps["as"]) => {
  switch (element) {
    case "h1":
      return 22;
    case "h2":
      return 18;
    case "h3":
      return 16;
    default:
      return 14;
  }
};

export const Anchor = (props: { isVisible: boolean; slug: string; element: BlockProps["as"] }) => (
  <Wrapper $isVisible={props.isVisible} href={`#${props.slug}`}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={elementToSize(props.element)}
      height={elementToSize(props.element)}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-link"
    >
      <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71" />
      <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71" />
    </svg>
  </Wrapper>
);
