import { StyledSvg } from "baseui/icon";
import React from "react";

export const ChangeLanguageIcon = (props: React.ComponentProps<typeof StyledSvg>) => {
  return (
    <StyledSvg viewBox="0 0 16 16" {...props}>
      <path d="M12 5.07677V5.69214C12 5.81091 11.9257 5.91921 11.8093 5.97029C11.693 6.02136 11.5553 6.00567 11.4553 5.93029L8 3.32051L4.54433 5.93029C4.44467 6.00567 4.307 6.02106 4.19033 5.97029C4.07367 5.91952 4 5.81091 4 5.69214V5.07677C4 4.98447 4.045 4.89708 4.12233 4.83862L7.789 2.06946C7.912 1.97685 8.08833 1.97685 8.21133 2.06946L11.878 4.83862C11.955 4.89708 12 4.98447 12 5.07677Z" />
      <path d="M4 10.9232L4 10.3079C4 10.1891 4.07433 10.0808 4.19067 10.0297C4.307 9.97864 4.44467 9.99433 4.54467 10.0697L8 12.6795L11.4557 10.0697C11.5553 9.99433 11.693 9.97895 11.8097 10.0297C11.9263 10.0805 12 10.1891 12 10.3079L12 10.9232C12 11.0155 11.955 11.1029 11.8777 11.1614L8.211 13.9305C8.088 14.0232 7.91167 14.0232 7.78867 13.9305L4.122 11.1614C4.045 11.1029 4 11.0155 4 10.9232Z" />
    </StyledSvg>
  );
};
