import { StyledSvg } from "baseui/icon";
import React from "react";

export const DocsDownArrowIcon = (props: React.ComponentProps<typeof StyledSvg>) => {
  return (
    <StyledSvg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 11C7.89527 11 7.79055 10.9592 7.71078 10.8779L3.61994 6.71125C3.50294 6.59208 3.46776 6.41292 3.53117 6.25708C3.59417 6.10125 3.74389 6 3.90916 6L12.0908 6C12.2561 6 12.4058 6.10125 12.4688 6.25708C12.5322 6.41292 12.4971 6.59208 12.3801 6.71125L8.28922 10.8779C8.20945 10.9592 8.10473 11 8 11Z"
        fill="black"
        fillOpacity="0.67"
      />
    </StyledSvg>
  );
};
