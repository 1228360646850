import { useStyletron } from "baseui";
import { StyledTableHeadCell } from "baseui/table-semantic";
import React from "react";

export const TableHeadCell = ({ children }: { children: React.ReactNode }) => {
  const [_css, $theme] = useStyletron();

  return (
    <StyledTableHeadCell
      $style={{
        backgroundColor: $theme.colors.primary50,
        fontSize: $theme.sizing.scale550,
        fontWeight: 400,
        color: $theme.colors.primary500,
      }}
    >
      {children}
    </StyledTableHeadCell>
  );
};
