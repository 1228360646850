import { StyledSvg } from "baseui/icon";
import React from "react";

export const DataDocIcon = (props: React.ComponentProps<typeof StyledSvg>) => {
  return (
    <StyledSvg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25 12C18.9605 12 14 16.9605 14 23V29C14 32.14 15.3482 34.981 17.4844 36.9922C15.3457 39.0001 14 41.8419 14 45V51C14 54.154 15.3407 56.992 17.4727 59C15.3407 61.008 14 63.846 14 67V73C14 79.066 18.936 84 25 84H56.4805C55.3345 82.25 54.3382 80.256 53.5742 78H25C22.242 78 20 75.756 20 73V67C20 64.244 22.242 62 25 62H52V56H25C22.242 56 20 53.756 20 51V45C20 42.244 22.242 40 25 40H71C72.686 40 74.1721 40.845 75.0781 42.125C76.5621 42.391 77.9127 43.1654 78.8047 44.3594C79.7427 45.6094 80.8539 46.5721 82.0039 47.3281V45C82.0039 41.841 80.659 38.9963 78.5195 36.9883C80.6534 34.9773 82 32.138 82 29V23C82 16.9605 77.0395 12 71 12H25ZM25 18H71C73.7965 18 76 20.2035 76 23V29C76 31.7951 73.7985 33.9979 71.0039 34H71H25C22.2035 34 20 31.7965 20 29V23C20 20.2035 22.2035 18 25 18ZM74 46C73.3885 46 72.7754 46.2538 72.3984 46.7578C67.0964 53.8258 58.084 53.957 58 53.957C56.896 53.957 56 54.853 56 55.957V68C56 87.338 73.3416 91.8974 73.5156 91.9414C73.6756 91.9814 73.836 92 74 92C74.164 92 74.3244 91.9814 74.4844 91.9414C74.6584 91.8974 92 87.338 92 68V55.957C92 54.857 91.1078 53.963 90.0078 53.957C89.9178 53.957 80.9036 53.8258 75.6016 46.7578C75.2246 46.2538 74.6115 46 74 46Z"
        fill="#545454"
      />
    </StyledSvg>
  );
};
