/**
 * Function decorator used to make observable instances memos if a read only flag is set.
 *
 * Observable classes will only do the magical observable/memo stuff if the function is being 'observed'.
 * For ReadOnlySchemaRoot/MobxQuickTree, there is no observables in order to improve performance, which
 * means that for normal mobx stuff, the memoization will not work. This decorator is used to turn mobx
 * getter observables into memoized functions if you set a 'memozie' flag on the class.
 */

import { assert } from "./utils";

type Memoizable = {
  memos: Record<string, any>;
  memoize: boolean;
};

export function memoize<T>(
  target: Memoizable,
  propertyKey: string,
  descriptor: TypedPropertyDescriptor<T>
): void | TypedPropertyDescriptor<T> {
  const oldGet = assert(descriptor.get, "can only memoize properties with a getter");

  descriptor.get = function (this: Memoizable) {
    if (this.memoize) {
      let value = this.memos[propertyKey];
      if (typeof value == "undefined") {
        value = oldGet.call(this);
        this.memos[propertyKey] = value;
      }
      return value;
    } else {
      return oldGet.call(this);
    }
  };
}
