import type { Options } from "prettier";
import parserBabel from "prettier/parser-babel";
import parserGraphql from "prettier/parser-graphql";
import parserTypescript from "prettier/parser-typescript";
import prettier from "prettier/standalone";
import type { StringChunk } from "./code/typescriptTemplate";

/** De-indents a string for presentation to a human */
export const dedent = (str: string) => {
  const lines = str.split("\n");
  const indent = Math.min(...lines.filter((line) => line.trim().length > 0).map((line) => line.match(/^\s*/)?.[0].length ?? 0)) ?? 0;
  if (lines[0].trim().length == 0) lines.shift();
  if (lines[lines.length - 1]?.trim().length == 0) lines.pop();

  return lines.map((line) => line.slice(indent).trimEnd()).join("\n");
};

export const prettifyCodeExample = (code: string | StringChunk, language: string, printWidth?: number) => {
  code = String(code);
  let parser: string;
  const options: Options = {};
  code = dedent(code);

  switch (language) {
    case "graphql": {
      parser = "graphql";
      break;
    }
    case "react":
    case "javascript": {
      parser = "babel";
      break;
    }
    case "json": {
      parser = "json5";
      options.quoteProps = "preserve";
      options.trailingComma = "none";
      break;
    }
    case "typescript": {
      parser = "typescript";
      break;
    }
    default: {
      return code;
    }
  }

  return prettier
    .format(code, {
      parser,
      plugins: [parserGraphql, parserBabel, parserTypescript],
      printWidth,
      ...options,
    })
    .trimEnd();
};
