import { expandBorderColors, expandBorderRadii } from "@gadgetinc/widgets";
import { useStyletron } from "baseui";
import type { ButtonProps } from "baseui/button";
import { Button as RawButton } from "baseui/button";
import { defaultsDeep, isUndefined } from "lodash";
import React, { forwardRef } from "react";
export { KIND, SIZE } from "baseui/button";

export type DocsButtonProps = ButtonProps & { $active?: boolean };

export const DocsButton = forwardRef<HTMLButtonElement, DocsButtonProps>((props, ref) => {
  const [_css, $theme] = useStyletron();

  const commonStyles = {
    ...expandBorderRadii($theme.borders.radius200),
    borderLeftWidth: "1px",
    borderRightWidth: "1px",
    borderBottomWidth: "1px",
    borderTopWidth: "1px",
    borderLeftStyle: "solid",
    borderTopStyle: "solid",
    borderRightStyle: "solid",
    borderBottomStyle: "solid",
    paddingTop: $theme.sizing.scale200,
    paddingBottom: $theme.sizing.scale200,
    paddingLeft: $theme.sizing.scale500,
    paddingRight: $theme.sizing.scale400,
  };

  const outlineStyles = {
    oulineWidth: $theme.sizing.scale300,
    outlineStyle: "solid",
    outlineOffset: "1px",
    outlineColor: $theme.colors.electricBlue500,
  };

  let overrides;
  if (props.kind == "primary" || isUndefined(props.kind)) {
    overrides = {
      BaseButton: {
        style: {
          ...commonStyles,
          backgroundColor: $theme.colors.electricBlue500,
          color: $theme.colors.white,
          ...expandBorderColors($theme.colors.accent600),
          boxShadow: $theme.lighting.shadow400,
          ":hover": {
            backgroundColor: $theme.colors.electricBlue600,
          },
          ":focus": outlineStyles,
        },
      },
    };
  } else if (props.kind == "minimal") {
    overrides = {
      BaseButton: {
        style: {
          fontWeight: 500,
          backgroundColor: props.$active ? $theme.colors.primary700 : "transparent",
          color: props.$active ? $theme.colors.accent700 : $theme.colors.electricBlue500,
          ":hover": {
            color: $theme.colors.electricBlue600,
            backgroundColor: "transparent",
          },
          ":focus": outlineStyles,
          ":disabled": {
            colors: $theme.colors.primary400,
          },
        },
      },
    };
  } else if (props.kind == "tertiary") {
    overrides = {
      BaseButton: {
        style: {
          ...commonStyles,
          backgroundColor: "transparent",
          color: $theme.colors.primary700,
          boxShadow: $theme.lighting.shadow400,
          ...expandBorderColors($theme.colors.primary300),
          ":hover": {
            backgroundColor: $theme.colors.primary50,
          },
          ":focus": outlineStyles,
        },
      },
    };
  } else if (props.kind == "secondary") {
    overrides = {
      BaseButton: {
        style: {
          ...commonStyles,
          backgroundColor: $theme.colors.white,
          color: $theme.colors.primary700,
          ...expandBorderColors($theme.colors.primary300),
          boxShadow: $theme.lighting.shadow400,
          ":hover": {
            backgroundColor: $theme.colors.primary50,
          },
          ":focus": outlineStyles,
        },
      },
    };
  }
  const buttonOverrides = defaultsDeep(props.overrides, overrides);

  return <RawButton ref={ref} {...props} overrides={buttonOverrides} />;
});
