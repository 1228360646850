import { useStyletron } from "baseui";
import { StyledSvg } from "baseui/icon";
import React from "react";

export const DocsWarningIcon = (props: React.ComponentProps<typeof StyledSvg>) => {
  const [_css, $theme] = useStyletron();

  return (
    <StyledSvg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 10C2 5.59025 5.59025 2 10 2C14.4097 2 18 5.59025 18 10C18 14.4097 14.4097 18 10 18C5.59025 18 2 14.4097 2 10ZM9.1913 6.46181H10.8085L10.6744 10.7769H9.32541L9.1913 6.46181ZM10.0025 12.0959C10.5505 12.0959 10.8788 12.3908 10.8788 12.8941C10.8788 13.3881 10.5505 13.6819 10.0025 13.6819C9.4505 13.6819 9.11968 13.3881 9.11968 12.8941C9.11968 12.3908 9.44983 12.0959 10.0025 12.0959Z"
        fill={$theme.colors.warning500}
      />
    </StyledSvg>
  );
};
