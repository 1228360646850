import type { SearchResponse } from "@algolia/client-search";
import algoliasearch from "algoliasearch/lite";
import { debounce } from "lodash";
import { useEffect } from "react";
import SearchInsights from "search-insights";
import { trackEvent } from "web/src/lib/eventTracker";
import type { FullMetaBlob } from "./chrome/FullMetaBlob";

export interface DocsSearchDocument {
  objectID: string;
  url: string;
  environmentId?: bigint | undefined;
  environmentVersion?: number | undefined;
  docsVersion?: string | undefined;
  docsVersionSpecific: boolean;
  release: string;
  environmentSpecific: boolean;
  content: string | null;
  category1: string | null;
  category2: string | null;
  category3: string | null;
  category4: string | null;
  category5: string | null;
  category6: string | null;
  headingLevel: number | null;
  pagePosition: number | null;
  boost: number | null;
}

export const DocsSearchFields = ["url", "content", "category1", "category2", "category3", "category4", "category5", "category6"];

const appId = "UHA3XWYIMK";
const apiKey = "976162b97f29a6861d69e410cedd59c3";
const client = algoliasearch(appId, apiKey);
export const indexName = `${process.env.NODE_ENV || "development"}_docs_search_v1`;
export const index = client.initIndex(indexName);

SearchInsights("init", { appId, apiKey });

export const useSetSearchInsightsToken = (userId?: string) => {
  return useEffect(() => {
    if (userId) {
      SearchInsights("setUserToken", "user-" + userId);
    }
  }, [userId]);
};

const trackingAppDetails = (app: FullMetaBlob) => {
  let application_id: string | undefined = undefined;
  let environment_id: string | undefined = undefined;

  if (!app.isExample) {
    application_id = app.id;
    environment_id = app.environmentID;
  }
  return { application_id, environment_id };
};

export const trackSearchResults = debounce((currentApp: FullMetaBlob, searchTerm: string, response: SearchResponse<DocsSearchDocument>) => {
  trackEvent(
    "Docs Search",
    {
      ...trackingAppDetails(currentApp),
      search_term: searchTerm,
      num_hits: response.nbHits,
      top_hit: response.hits[0]?.url,
      query_id: response.queryID,
    },
    "docs"
  );
}, 1000);

export const trackSearchResultClicked = (currentApp: FullMetaBlob, queryID: string, objectID: string, position: number) => {
  trackEvent(
    "Docs Search Result Clicked",
    {
      ...trackingAppDetails(currentApp),
      query_id: queryID,
      object_id: objectID,
      position,
    },
    "docs"
  );

  SearchInsights("clickedObjectIDsAfterSearch", {
    index: indexName,
    eventName: "Search Result Clicked",
    queryID: queryID,
    objectIDs: [objectID],
    positions: [position],
  });
};
