import { StyledSvg } from "baseui/icon";
import React from "react";

export const EnvironmentsDocIcon = (props: React.ComponentProps<typeof StyledSvg>) => (
  <StyledSvg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29 12C19.6467 12 12 19.6467 12 29C12 38.3533 19.6467 46 29 46C38.3533 46 46 38.3533 46 29C46 19.6467 38.3533 12 29 12ZM61 14C56.065 14 52 18.065 52 23V35C52 39.935 56.065 44 61 44H73C77.935 44 82 39.935 82 35V23C82 18.065 77.935 14 73 14H61ZM29 18C35.1107 18 40 22.8893 40 29C40 35.1107 35.1107 40 29 40C22.8893 40 18 35.1107 18 29C18 22.8893 22.8893 18 29 18ZM61 20H73C74.693 20 76 21.307 76 23V35C76 36.693 74.693 38 73 38H61C59.307 38 58 36.693 58 35V23C58 21.307 59.307 20 61 20ZM66.9844 49.9922C65.0581 49.9856 63.1327 50.5703 61.5 51.75L53.418 57.582C50.1501 59.9381 48.7642 64.1583 49.9961 67.9922L53.0469 77.4844C54.2784 81.3172 57.864 83.9397 61.8906 83.9531L71.8594 83.9844C75.886 83.9974 79.489 81.3983 80.7461 77.5742L83.8594 68.1055C85.1178 64.2803 83.7552 60.0489 80.5039 57.6719L72.4609 51.7852V51.7891C70.8354 50.5985 68.9107 49.9988 66.9844 49.9922ZM29 52.125C26.2604 52.125 23.5229 53.4455 22.0156 56.0859L13.0938 71.7188C10.0589 77.0381 13.9478 84 20.0781 84H37.9219C44.0536 84 47.942 77.0389 44.9062 71.7188L35.9883 56.0859C34.481 53.4455 31.7396 52.125 29 52.125ZM66.9648 55.9805C67.6475 55.9829 68.3315 56.1994 68.918 56.6289L76.9609 62.5117L76.9648 62.5156C78.1356 63.371 78.6134 64.8527 78.1602 66.2305H78.1562L75.0469 75.6992C74.5924 77.0819 73.3307 77.9897 71.8789 77.9844L61.9102 77.9531C60.4584 77.9489 59.2019 77.0304 58.7578 75.6484L55.7109 66.1602C55.2668 64.778 55.7536 63.2971 56.9297 62.4492V62.4453L65.0117 56.6133C65.599 56.189 66.2822 55.9781 66.9648 55.9805ZM29 57.875C29.6624 57.875 30.3266 58.269 30.7773 59.0586L39.6953 74.6914C40.6036 76.2832 39.6182 78 37.9219 78H20.0781C18.3818 78 17.3952 76.2836 18.3047 74.6914L27.2266 59.0586C27.6773 58.269 28.3376 57.875 29 57.875Z"
      fill="#545454"
    />
  </StyledSvg>
);
