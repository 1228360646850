import { BookIcon } from "@gadgetinc/widgets/src/icons/BookIcon";
import { BookOpenIcon } from "@gadgetinc/widgets/src/icons/BookOpenIcon";
import { GellyIcon } from "@gadgetinc/widgets/src/icons/GellyIcon";
import type { StyledSvg } from "baseui/icon";
import React from "react";
import { currentDocsArea } from "./chrome/DocsContext";

export const getAreaIcon = (path: string, iconProps: React.ComponentProps<typeof StyledSvg> = { $size: "16px" }): JSX.Element => {
  const docsArea = currentDocsArea(path);
  switch (docsArea) {
    case "guides":
      return <BookOpenIcon {...iconProps} />;
    case "reference":
      return <GellyIcon backgroundFill={"currentColor"} {...iconProps} />;
    default:
      return <BookIcon {...iconProps} />;
  }
};
