import { isExcludedNamespaceSegment } from "@gadgetinc/conventions";
import { ReservedTopLevelNames, ValidGraphQLIdentifier } from "../../EditValidations";
import { ProblemMessage } from "../ProblemsList";

export const getPotentialNamespaceProblems = (attr: { supportNamespace: boolean; namespace: string[] }): ProblemMessage[] => {
  const { supportNamespace, namespace } = attr;
  const problems: ProblemMessage[] = [];

  if (namespace.length === 0) {
    return problems; // No namespace => no problems
  }

  if (ReservedTopLevelNames.includes(namespace[0])) {
    problems.push(ProblemMessage.quickString(`"${namespace[0]}" is a reserved Gadget name`));
  }

  if (!supportNamespace) {
    problems.push(ProblemMessage.quickString(`namespaces are not supported`));
  }

  if (namespace.some((segment) => (!ValidGraphQLIdentifier.test(segment) && !isExcludedNamespaceSegment(segment)) || segment === "")) {
    problems.push(ProblemMessage.quickString(`invalid namespace segments`));
  }

  return problems;
};
