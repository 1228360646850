import { CopyButton, Row, expandBorderRadii } from "@gadgetinc/widgets";
import darkTheme from "@gadgetinc/widgets/src/code-block-themes";
import { styled, useStyletron, withStyle } from "baseui";
import { toaster } from "baseui/toast";
import type { ReactNode } from "react";
import React, { useCallback } from "react";
import type { StyleObject } from "styletron-react";
import { CodeHighlight, useCodeHighlightTheme } from "./CodeHighlight";
import "./GellyPrism";
import { LanguageIndicator, languageLabel } from "./LanguageIndicator";

export const handleCopy = async (text: string, toastContent?: ReactNode) => {
  await navigator.clipboard.writeText(text);
  if (toastContent) {
    toaster.info(toastContent, { autoHideDuration: 2000, closeable: false });
  }
};

export const CodeBlockContainer = (props: { children: React.ReactNode; $style?: StyleObject; minimal?: boolean }) => {
  const [css, $theme] = useStyletron();

  return (
    <div
      className={css({
        position: "relative",
        backgroundColor: darkTheme.plain.backgroundColor,
        ...expandBorderRadii(props.minimal ? 0 : $theme.borders.radius200),
        marginBottom: $theme.sizing.scale700,
        boxShadow: $theme.lighting.shadow400,
        ...$theme.borders.border100,
        ...props.$style,
      })}
    >
      {props.children}
    </div>
  );
};

export const CodeBlockTitleBar = (props: { children: React.ReactNode; minimal?: boolean; $style?: StyleObject }) => {
  const [_css, $theme] = useStyletron();
  const style: StyleObject = {
    paddingLeft: $theme.sizing.scale700,
    paddingRight: $theme.sizing.scale200,
    paddingTop: $theme.sizing.scale200,
    paddingBottom: $theme.sizing.scale200,
    ...(props.minimal && {
      borderBottomWidth: "1px",
      borderBottomStyle: "solid",
      borderBottomColor: $theme.colors.primary700,
      marginBottom: $theme.sizing.scale700,
    }),
    ...props.$style,
  };

  return (
    <Row $gap={$theme.sizing.scale200} $style={style}>
      {props.children}
    </Row>
  );
};

export const CodeBlockTitle = styled("div", ({ $theme }) => ({
  flexGrow: 1,
  ...$theme.typography.ParagraphSmall,
  marginTop: "0",
  marginBottom: "0",
  color: $theme.colors.primary400,
}));

export const CodeBlockTitleMinimal = withStyle(CodeBlockTitle, () => ({
  fontSize: "14px",
  fontWeight: 700,
}));

export const CodeBlockFilename = withStyle(CodeBlockTitle, () => ({
  fontWeight: 400,
  textTransform: "none",
}));

export const CodeBlockTitleBarSpacer = styled("div", {
  flexGrow: 1,
});

export const CodeBlock = (props: {
  title?: ReactNode;
  children: string;
  className?: string;
  language?: string;
  hideLanguageLabel?: boolean;
  disallowCopy?: boolean;
  style?: any;
}) => {
  const [css, $theme] = useStyletron();
  const language = props.language || props.className?.replace(/language-/, "");

  const { theme, labelColor } = useCodeHighlightTheme("light");

  const copyCode = useCallback(() => {
    if (!props.disallowCopy) void handleCopy(props.children);
  }, [props.children, props.disallowCopy]);

  return (
    <CodeBlockContainer $style={{ backgroundColor: theme.plain.backgroundColor, ...props.style }}>
      <CodeBlockTitleBar>
        {props.title && <CodeBlockTitle>{props.title}</CodeBlockTitle>}
        <CodeBlockTitleBarSpacer />
        {language && !props.hideLanguageLabel && <LanguageIndicator color={labelColor}>{languageLabel(language)}</LanguageIndicator>}
        {!props.disallowCopy && <CopyButton onClick={copyCode} />}
      </CodeBlockTitleBar>

      <div className={css({ overflowX: "auto", scrollbarColor: `${$theme.colors.primary300} transparent` })} onClick={copyCode}>
        <CodeHighlight kind="light" language={language?.toLowerCase() ?? "unknown"} className={props.className}>
          {props.children}
        </CodeHighlight>
      </div>
    </CodeBlockContainer>
  );
};
