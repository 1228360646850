import { StyledSvg } from "baseui/icon";
import React from "react";

export const DownLeftArrowIcon = (props: React.ComponentProps<typeof StyledSvg>) => {
  return (
    <StyledSvg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M1.50791 2.65924C1.6404 2.66131 1.76666 2.71589 1.85895 2.81098C1.95123 2.90607 2.002 3.0339 2.0001 3.1664V8.1664C2.0001 8.63248 2.36736 8.99974 2.83343 8.99974H13.2931L10.8132 6.51992C10.7411 6.44967 10.6919 6.35927 10.672 6.26057C10.652 6.16187 10.6624 6.05946 10.7017 5.96673C10.7409 5.87401 10.8072 5.7953 10.892 5.7409C10.9767 5.6865 11.0759 5.65895 11.1765 5.66184C11.3064 5.66563 11.4297 5.7198 11.5203 5.81289L14.8536 9.14622C14.9473 9.23999 15 9.36715 15 9.49973C15 9.63232 14.9473 9.75948 14.8536 9.85325L11.5203 13.1866C11.4742 13.2346 11.419 13.2729 11.358 13.2993C11.2969 13.3257 11.2312 13.3396 11.1647 13.3403C11.0981 13.341 11.0322 13.3284 10.9706 13.3032C10.909 13.2781 10.853 13.2409 10.806 13.1938C10.7589 13.1468 10.7218 13.0909 10.6966 13.0293C10.6715 12.9677 10.6589 12.9017 10.6596 12.8352C10.6602 12.7687 10.6742 12.7029 10.7006 12.6419C10.727 12.5808 10.7653 12.5256 10.8132 12.4796L13.2931 9.99973H2.83343C1.82684 9.99973 1.0001 9.17299 1.0001 8.1664V3.1664C0.999138 3.09948 1.01162 3.03305 1.03681 2.97104C1.062 2.90903 1.09938 2.85271 1.14673 2.80541C1.19409 2.75812 1.25046 2.72081 1.3125 2.6957C1.37454 2.67059 1.44099 2.65819 1.50791 2.65924Z" />
    </StyledSvg>
  );
};
