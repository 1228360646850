import { StyledSvg } from "baseui/icon";
import React from "react";

export const DocsRightArrowIcon = (props: React.ComponentProps<typeof StyledSvg>) => {
  return (
    <StyledSvg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 8C11 8.10473 10.9592 8.20945 10.8779 8.28922L6.71125 12.3801C6.59208 12.4971 6.41292 12.5322 6.25708 12.4688C6.10125 12.4058 6 12.2561 6 12.0908V3.90916C6 3.74389 6.10125 3.59417 6.25708 3.53117C6.41292 3.46776 6.59208 3.50294 6.71125 3.61994L10.8779 7.71078C10.9592 7.79055 11 7.89527 11 8Z"
        fill="black"
        fillOpacity="0.67"
      />
    </StyledSvg>
  );
};
