import { NewPill } from "@gadgetinc/widgets";
import React from "react";

export const HighlightLatest = ({ text }: { text: string | undefined }) => {
  if (!text) return null;

  const keyword = "Latest";
  const index = text.indexOf(keyword);

  if (index !== -1) {
    return (
      <span>
        {text.substring(0, index)}
        <NewPill>{keyword}</NewPill>
        {text.substring(index + keyword.length)}
      </span>
    );
  }

  return <span>{text}</span>;
};
