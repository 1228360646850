import { StyledSvg } from "baseui/icon";
import React from "react";

export const GettingStartedIcon = (props: React.ComponentProps<typeof StyledSvg>) => {
  return (
    <StyledSvg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.0001 12C16.2044 12.0001 15.4414 12.3162 14.8788 12.8788C14.3162 13.4414 14.0001 14.2044 14.0001 15V85C13.9944 85.3975 14.0679 85.7922 14.2161 86.1611C14.3644 86.53 14.5844 86.8658 14.8636 87.1489C15.1427 87.432 15.4753 87.6568 15.8421 87.8103C16.2089 87.9637 16.6025 88.0427 17.0001 88.0427C17.3976 88.0427 17.7912 87.9637 18.158 87.8103C18.5248 87.6568 18.8574 87.432 19.1365 87.1489C19.4157 86.8658 19.6358 86.53 19.784 86.1611C19.9322 85.7922 20.0057 85.3975 20.0001 85V66H83.0001C83.5571 65.9997 84.1031 65.8443 84.5769 65.5512C85.0507 65.2582 85.4335 64.839 85.6825 64.3406C85.9314 63.8422 86.0368 63.2844 85.9866 62.7296C85.9364 62.1748 85.7328 61.6448 85.3985 61.1992L68.7501 39L85.3985 16.8008C85.7328 16.3552 85.9364 15.8252 85.9866 15.2704C86.0368 14.7156 85.9314 14.1578 85.6825 13.6594C85.4335 13.161 85.0507 12.7418 84.5769 12.4488C84.1031 12.1557 83.5571 12.0003 83.0001 12H17.0001ZM20.0001 18H77.0001L62.6016 37.1992C62.2118 37.7187 62.0011 38.3506 62.0011 39C62.0011 39.6494 62.2118 40.2813 62.6016 40.8008L77.0001 60H20.0001V18Z"
        fill="#545454"
      />
    </StyledSvg>
  );
};
