import { useStyletron } from "baseui";
import { StyledSvg } from "baseui/icon";
import React from "react";

export const DocsLightbulbIcon = (props: React.ComponentProps<typeof StyledSvg>) => {
  const [_css, $theme] = useStyletron();
  return (
    <StyledSvg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M4.353 1.677a.667.667 0 10-.018 1.333.667.667 0 00.018-1.333zm11.314 0a.666.666 0 10-.021 1.333.666.666 0 00.02-1.333zM10 2a6 6 0 00-6 6c0 3.333 2.667 4.667 4 7.333h4c1.333-2.666 4-4 4-7.333a6 6 0 00-6-6zm0 2v3.333h2.667L10 12V8.667H7.333L10 4zM2 7.333a.667.667 0 100 1.334.667.667 0 000-1.334zm16 0a.667.667 0 100 1.334.667.667 0 000-1.334zM4.353 12.99a.669.669 0 00-.677.667.667.667 0 10.677-.667zm11.312.002a.665.665 0 00-.48 1.138.667.667 0 10.48-1.138zM8 16.667v.666c0 .737.597 1.334 1.333 1.334a.667.667 0 101.334 0c.736 0 1.333-.597 1.333-1.334v-.666H8z"
        fill={props.color || $theme.colors.primary500}
      />
    </StyledSvg>
  );
};
