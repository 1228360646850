import { Pill } from "@gadgetinc/widgets";
import { DownLeftArrowIcon } from "@gadgetinc/widgets/src/icons/DownLeftArrowIcon";
import { useStyletron } from "baseui";
import { omit } from "lodash";
import React from "react";
import { EnvironmentSlug, LegacyEnvironmentName } from "state-trees/src/Environment";
import { StyleObject } from "styletron-react";

export type EnvironmentNameTagProps = JSX.IntrinsicElements["span"] & {
  environment: LegacyEnvironmentName | EnvironmentSlug;
  $style?: StyleObject;
};

/**
 * Span which auto colors the text based on the environment name
 * No background
 **/
export const EnvironmentNameTag = (props: EnvironmentNameTagProps) => {
  // eslint-disable-next-line react/no-unknown-property
  const isProduction = props.environment.toLowerCase() == "production";
  const [css, $theme] = useStyletron();

  return (
    <Pill $color={isProduction ? "production" : "teal"} title={props.environment.toLowerCase()} $style={{ maxWidth: "100%" }}>
      <span
        className={css({
          color: isProduction ? $theme.colors.contentProduction : $theme.colors.contentDevelopment,
          fontWeight: 500,
          display: "inline-flex",
          alignItems: "center",
          whiteSpace: "nowrap",
          maxWidth: "100%",
          ...props.$style,
        })}
        data-widget="environment-name-tag"
        {...omit(props, ["$style", "environment"])}
        title={props.environment.toLowerCase()}
      >
        {props.children ?? (
          <>
            {!isProduction && <DownLeftArrowIcon $style={{ marginRight: $theme.sizing.scale200, minWidth: "16px" }} />}
            <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{props.environment.toLowerCase()}</span>
          </>
        )}
      </span>
    </Pill>
  );
};

/**
 * Span which auto colors the text based on the environment name
 * Pill shaped with a background
 **/
export const EnvironmentNamePill = (props: EnvironmentNameTagProps) => {
  const [_css, $theme] = useStyletron();
  return (
    <EnvironmentNameTag
      {...props}
      $style={{
        borderRadius: $theme.borders.radius200,
        padding: "0px 4px",
        ...props.$style,
      }}
    />
  );
};
