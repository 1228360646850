import { Button, paddingHorizontal, paddingVertical } from "@gadgetinc/widgets";
import { SidebarNavBreakpoint, WideSidebarNavBreakpoint } from "@gadgetinc/widgets/src/DocsTheme";
import { APIPlaygroundIcon } from "@gadgetinc/widgets/src/icons/APIPlaygroundIcon";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { useNavigationDetails } from "fastify-renderer/client/react";
import type { ForwardedRef } from "react";
import React, { forwardRef, useContext, useEffect, useMemo, useRef } from "react";
import { useFlags } from "web/src/lib/flags";
import { useRouter } from "wouter";
import { shouldShowEnvironmentSelector, shouldShowVersionSelector } from "../../utils/shouldShowVersionSelector";
import { Divider } from "../Divider";
import { DocsContext, currentDocsArea } from "../DocsContext";
import { DocsNavLink } from "./DocsNavLink";
import { APIEnvironmentSelector, DocsVersionSelector, currentDocsVersion } from "./DocsVersionSelector";
import { StatefulNavSidebar } from "./StatefulNavSidebar";
import { navItemIsActive, type GadgetItemProps } from "./navigation-types";
import { useDocsLocation } from "./useDocsLocation";

export const SmallSidebarWidth = "250px";
export const LargeSidebarWidth = "280px";

export const DocsLeftNav = forwardRef((_props, _ref?: ForwardedRef<HTMLDivElement>) => {
  const [css, $theme] = useStyletron();
  const [location] = useDocsLocation();
  const [_, navigationLocation] = useNavigationDetails();
  const currentArea = currentDocsArea(location);
  const router = useRouter();
  const flags = useFlags();
  const { currentApp, availableApps, topLevelItems, currentUser, setShowTsLanguage, showTsLanguage } = useContext(DocsContext);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const currentVersion = currentDocsVersion(location, currentApp);

  const secondLevelItems = useMemo<GadgetItemProps[]>(() => {
    const section = topLevelItems.find((item) => navItemIsActive(item, navigationLocation ?? location));
    if (section) {
      return section.subNav ?? [];
    } else {
      return topLevelItems.find((item) => item.itemId == currentVersion.rootURL)?.subNav ?? [];
    }
  }, [topLevelItems, navigationLocation, location, currentVersion.rootURL]);

  const defaultNavStyles = css({
    display: "none",
    minWidth: 0,
    backgroundColor: $theme.colors.primary50,
    borderRightWidth: $theme.borders.border100.borderWidth,
    borderRightStyle: $theme.borders.border100.borderStyle as any,
    borderRightColor: $theme.colors.primary200,
    [`@media screen and ${SidebarNavBreakpoint}`]: {
      display: "block",
      position: "fixed",
      height: "calc(100% - 38px)",
      scrollbarWidth: "none",
      "::-webkit-scrollbar": {
        display: "none",
      },
      overflowY: "auto",
      overflowX: "hidden",
      width: SmallSidebarWidth,
    },
    [`@media screen and ${WideSidebarNavBreakpoint}`]: {
      width: LargeSidebarWidth,
    },
  });

  const saveNavScrolltopPosition = (_event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (containerRef?.current) {
      localStorage.setItem("position", `${containerRef.current.scrollTop}`);
    }
  };

  // TODO: Improve this so that it accounts for the layout shift that happens with the accordion
  useEffect(() => {
    const scrollToPosition = localStorage.getItem("position");
    if (scrollToPosition && containerRef?.current && !router.navigationHistory?.current?.replace) {
      containerRef.current.scrollTo(0, +scrollToPosition);
      localStorage.setItem("position", "0");
    }
  }, [location]);

  const showVersionSelector = shouldShowVersionSelector(currentArea, availableApps, flags, currentApp, currentUser);
  const showEnvironmentSelector = shouldShowEnvironmentSelector(currentArea, availableApps, flags, currentApp, currentUser);

  return (
    <div data-testid="docs-left-nav" ref={containerRef} className={defaultNavStyles} onClick={saveNavScrolltopPosition}>
      {showVersionSelector && (
        <>
          <div
            style={{
              ...paddingHorizontal($theme.sizing.scale300),
              ...paddingVertical($theme.sizing.scale300),
            }}
          >
            <DocsVersionSelector />
          </div>
        </>
      )}

      {currentUser && currentUser.isStaff && flags.features.typescript && (
        // TODO: this is a temporary button to toggle the JS/TS version of code snippets so we have something to work with.
        // It will be removed once the nicer language selector is implemented.
        <Button onClick={() => setShowTsLanguage((old) => !old)} size="compact" kind="secondary">
          Show {showTsLanguage ? "JS" : "TS"}
        </Button>
      )}
      {showEnvironmentSelector && (
        <>
          <APIEnvironmentSelector />
          <Divider />
        </>
      )}
      <div
        className={css({
          backgroundColor: $theme.colors.primary50,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
          height: "calc(100% - 75px)",
        })}
      >
        <div data-testid="docsStatefulLeftNav" className={css({ ...paddingHorizontal($theme.sizing.scale300) })}>
          <StatefulNavSidebar subNav={secondLevelItems} depth={1} />
          {currentArea == "guides" && (
            <>
              <Block display="flex" justifyContent="center" alignItems="center" height="12px">
                <Block height="1px" width="100%" backgroundColor="gainsboro" />
              </Block>
              <DocsNavLink anchorProps={{ href: "/guides/gadget-framework" }}> Gadget framework </DocsNavLink>
              <DocsNavLink anchorProps={{ href: "/guides/glossary" }}> Glossary </DocsNavLink>
              <DocsNavLink anchorProps={{ href: "/guides/faq" }}> FAQ </DocsNavLink>
            </>
          )}
        </div>
        <div
          className={css({
            marginTop: $theme.sizing.scale900,
            ...paddingHorizontal($theme.sizing.scale300),
            paddingBottom: $theme.sizing.scale500,
          })}
        >
          {currentArea == "api" && (
            <DocsNavLink
              anchorProps={{
                href: `${currentApp.canonicalRenderURL}api/playground/javascript?environment=${currentApp.environment.slug}`,
                target: "_blank",
              }}
              icon={<APIPlaygroundIcon size={16} />}
            >
              API playground
            </DocsNavLink>
          )}
        </div>
      </div>
    </div>
  );
});
