import { DevelopmentEnvironmentName, EnvironmentSlug, EnvironmentType } from "state-trees/src/Environment";
import { LATEST_API_VERSION } from "state-trees/src/connections/shopify/ShopifyApiVersion";
import type { FullMetaBlob } from "./chrome/FullMetaBlob";

// when the user has no app selected, we use this app's slug to show them an example API reference
export const ExampleAppMeta: FullMetaBlob = {
  id: "<your-app-id>",
  name: "Developer",
  isExample: true,
  slug: "example-app",
  environmentsMetaData: [
    {
      frameworkVersion: "^0.3.1",
      slug: "development" as EnvironmentSlug,
      type: EnvironmentType.Development,
    },
  ],
  environment: {
    frameworkVersion: "^0.3.1",
    slug: "development" as EnvironmentSlug,
    type: EnvironmentType.Development,
  },
  environmentID: "1",
  environmentName: DevelopmentEnvironmentName,
  models: [],
  hasGlobalActions: false,
  hasShopifyConnection: false,
  hasSplitEnvironments: true,
  hasLegacyEffectCards: false,
  canonicalRenderURL: "https://example-app.gadget.app",
  developmentRenderURL: "https://example-app--development.gadget.app",
  productionRenderURL: "https://example-app.gadget.app",
  editURL: "https://example-app.gadget.app/edit",
  graphQLEndpoint: "https://example-app.gadget.app/api/graphql",
  developmentGraphQLEndpoint: "https://example-app--development.gadget.app/api/graphql",
  productionGraphQLEndpoint: "https://example-app.gadget.app/api/graphql",
  jsPackageIdentifier: "@gadget-client/example-app",
  shopifyConnectionApiVersion: LATEST_API_VERSION,
  hasTemplateConfiguration: false,
  hasTemplateSetup: false,
  multiEnvironmentEnabled: true,
  currentEnvironmentRenderURL: "https://example-app--development.gadget.app",
  languagePreference: "javascript",
  defaultFileExtension: "js",
};
