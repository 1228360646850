import { useStyletron } from "baseui";
import { StyledSvg } from "baseui/icon";
import React from "react";

export const APIPlaygroundIcon = (props: React.ComponentProps<typeof StyledSvg>) => {
  const [_css, $theme] = useStyletron();

  return (
    <StyledSvg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M5.02797 2.65003H3.8393C3.68298 2.64985 3.52883 2.68051 3.38437 2.74025C3.23991 2.79999 3.10866 2.88764 2.99812 2.99818C2.88759 3.10871 2.79994 3.23997 2.7402 3.38443C2.68046 3.52888 2.6498 3.6837 2.64997 3.84003V6.21669C2.65006 6.37285 2.61938 6.52749 2.55968 6.67178C2.49998 6.81607 2.41244 6.94719 2.30205 7.05764C2.19167 7.16809 2.0606 7.2557 1.91634 7.31548C1.77208 7.37526 1.61746 7.40603 1.4613 7.40603H0.866638V8.59469H1.4613C1.77656 8.59469 2.0789 8.71993 2.30182 8.94285C2.52474 9.16576 2.64997 9.46811 2.64997 9.78336V12.1614C2.64997 12.8147 3.2033 13.19 3.8393 13.35H5.02797V12.1614H3.8393V9.18869C3.8393 9.03254 3.80854 8.87792 3.74876 8.73366C3.68898 8.5894 3.60137 8.45833 3.49092 8.34794C3.38047 8.23756 3.24935 8.15002 3.10506 8.09032C2.96077 8.03062 2.80613 7.99994 2.64997 8.00003C2.80607 8.00011 2.96066 7.96946 3.10491 7.9098C3.24915 7.85014 3.38024 7.76266 3.49068 7.65235C3.60112 7.54203 3.68875 7.41104 3.74857 7.26686C3.80839 7.12268 3.83922 6.96812 3.8393 6.81203V3.84003H5.02797V2.65003Z"
        fill={props.color ?? "currentColor"}
      />
      <path
        d="M12.617 2.74057C12.4727 2.68079 12.3181 2.65003 12.162 2.65003H10.972V3.83936H12.1613V6.81136C12.1613 7.12661 12.2865 7.42896 12.5095 7.65187C12.7324 7.87479 13.0347 8.00003 13.35 8.00003C13.0348 8.0002 12.7327 8.12552 12.5099 8.34841C12.2871 8.57131 12.162 8.87356 12.162 9.18869V12.1614H10.9726V13.35H12.162C12.4772 13.35 12.7796 13.2248 13.0025 13.0019C13.2254 12.779 13.3506 12.4766 13.3506 12.1614V9.78336C13.3506 9.62726 13.3814 9.47269 13.4411 9.32848C13.5009 9.18426 13.5884 9.05322 13.6988 8.94285C13.8092 8.83247 13.9402 8.74491 14.0844 8.68518C14.2286 8.62544 14.3832 8.59469 14.5393 8.59469H15.134V7.40603H14.5393C14.3832 7.40603 14.2285 7.37526 14.0843 7.31548C13.94 7.2557 13.8089 7.16809 13.6986 7.05764C13.5882 6.94719 13.5006 6.81607 13.4409 6.67178C13.3812 6.52749 13.3505 6.37285 13.3506 6.21669V3.83936C13.3507 3.68321 13.32 3.52856 13.2603 3.38427C13.2007 3.23998 13.1131 3.10886 13.0027 2.99841C12.8923 2.88797 12.7613 2.80035 12.617 2.74057Z"
        fill={props.color ?? "currentColor"}
      />
      <path
        d="M8.45413 4.0809C8.50116 4.05294 8.55484 4.03815 8.60956 4.03809C8.69038 4.03809 8.7679 4.0702 8.82506 4.12735C8.88221 4.1845 8.91432 4.26202 8.91432 4.34285C8.91427 4.37332 8.90966 4.40362 8.90063 4.43273L8.45718 7.39047H10.1334C10.3016 7.39047 10.4381 7.527 10.4381 7.69523C10.4381 7.76161 10.4123 7.81957 10.3762 7.86963L10.3792 7.87499L7.96432 11.7994C7.93847 11.8484 7.89976 11.8894 7.85236 11.918C7.80496 11.9466 7.75065 11.9618 7.69527 11.9619C7.61444 11.9619 7.53693 11.9298 7.47977 11.8726C7.42262 11.8155 7.39051 11.738 7.39051 11.6571C7.39046 11.6367 7.39245 11.6164 7.39646 11.5964L7.84765 8.60952H6.17146C6.09063 8.60952 6.01312 8.57741 5.95596 8.52025C5.89881 8.4631 5.8667 8.38558 5.8667 8.30475C5.86664 8.24339 5.8851 8.18283 5.91968 8.13213L8.34229 4.19702C8.36847 4.14897 8.40711 4.10887 8.45413 4.0809Z"
        fill={props.color ?? "currentColor"}
      />
    </StyledSvg>
  );
};
