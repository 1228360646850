import type { PrismTheme } from "prism-react-renderer";

type GadgetDarkTheme = PrismTheme;

export const gqlLightTheme: PrismTheme = {
  plain: {
    color: "hsl(208, 100%, 46%)",
    backgroundColor: "#ffffff",
  },
  styles: [
    {
      types: ["keyword"],
      style: {
        color: "hsl(240, 97%, 62%)",
      },
    },
    {
      types: ["variable"],
      style: {
        color: "hsl(242, 51%, 61%)",
      },
    },
    {
      types: ["punctuation"],
      style: {
        color: "hsl(0, 0%, 33%)",
      },
    },
    {
      types: ["property"],
      style: {
        color: "hsl(208, 100%, 46%)",
      },
    },
    {
      types: ["class-name", "attr-name"],
      style: {
        color: "hsl(188, 100%, 36%)",
      },
    },
  ],
};

/**
 * Below is Gafget's dark theme for code block components, used in the docs
 */

const theme: GadgetDarkTheme = {
  plain: {
    color: "#d6deeb",
    backgroundColor: "#011627",
  },
  styles: [
    {
      types: ["changed"],
      style: {
        color: "rgb(162, 191, 252)",
        fontStyle: "italic",
      },
    },
    {
      types: ["deleted"],
      style: {
        color: "rgba(239, 83, 80, 0.56)",
        fontStyle: "italic",
      },
    },
    {
      types: ["inserted", "attr-name"],
      style: {
        color: "rgb(173, 219, 103)",
        fontStyle: "italic",
      },
    },
    {
      types: ["comment"],
      style: {
        color: "rgb(99, 119, 119)",
        fontStyle: "italic",
      },
    },
    {
      types: ["string", "url"],
      style: {
        color: "#77AFE3",
      },
    },
    {
      types: ["variable"],
      style: {
        color: "rgb(214, 222, 235)",
      },
    },
    {
      types: ["number"],
      style: {
        color: "rgb(247, 140, 108)",
      },
    },
    {
      types: ["builtin", "char", "constant", "function"],
      style: {
        color: "#DF972B",
      },
    },
    {
      // This was manually added after the auto-generation
      // so that punctuations are not italicised
      types: ["punctuation"],
      style: {
        color: "#E8A6FF",
      },
    },
    {
      types: ["selector", "doctype"],
      style: {
        color: "rgb(199, 146, 234)",
        fontStyle: "italic",
      },
    },
    {
      types: ["class-name"],
      style: {
        color: "#d6deeb",
      },
    },
    {
      types: ["tag", "operator", "keyword"],
      style: {
        color: "#5AA861",
      },
    },
    {
      types: ["boolean"],
      style: {
        color: "rgb(255, 88, 116)",
      },
    },
    {
      types: ["property"],
      style: {
        color: "rgb(128, 203, 196)",
      },
    },
    {
      types: ["namespace"],
      style: {
        color: "rgb(178, 204, 214)",
      },
    },
  ],
};

export default theme;

export const purpleDarkTheme = {
  plain: {
    color: "#FFFFFF",
    backgroundColor: "#191830",
    selectionBackground: "#5706a288",
  },
  styles: [
    {
      types: ["comment", "punctuation"],
      style: {
        color: "#B362FF",
      },
    },
    {
      types: ["constant", "hexcode"],
      style: {
        color: "#FF628C",
      },
    },
    {
      types: ["string"],
      style: {
        color: "#A5FF90",
      },
    },
    {
      types: ["builtin", "attr-name", "selector", "tag"],
      style: {
        color: "#9EFFFF",
      },
    },
    {
      types: ["variable", "regex"],
      style: {
        color: "#E1EFFF",
      },
    },
    {
      types: ["keyword", "operator"],
      style: {
        color: "#FF9D00",
      },
    },
    {
      types: ["inserted"],
      style: {
        color: "#8efa00",
      },
    },
    {
      types: ["deleted"],
      style: {
        color: "#F16E6B",
      },
    },
    {
      types: ["function"],
      style: {
        color: "#FAD000",
      },
    },
  ],
};
