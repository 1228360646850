import { styled } from "baseui";

export const ListItem = styled("li", ({ $theme }) => ({
  marginLeft: $theme.sizing.scale800,
  ...$theme.typography.ParagraphMedium,
  gridColumn: 1,
}));

export const UnorderedList = styled("ul", ({ $theme }) => ({
  ...$theme.typography.ParagraphMedium,
  listStyleType: "circle",
  marginBottom: $theme.sizing.scale600,
  gridColumn: 1,
}));

export const OrderedList = styled("ol", ({ $theme }) => ({
  ...$theme.typography.ParagraphMedium,
  marginBottom: $theme.sizing.scale600,
  gridColumn: 1,
}));
