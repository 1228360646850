import { expandBorderLeft, expandBorderRight, expandBorderTop } from "@gadgetinc/widgets";
import { styled, useStyletron } from "baseui";
import { StyledTable } from "baseui/table-semantic";
import React from "react";
import type { StyleObject } from "styletron-standard";
export { StyledTableBodyCell as TD, StyledTableHeadCell as TH, StyledTableBodyRow as TR } from "baseui/table-semantic";

export const Table = (props: { children: React.ReactNode; $style?: StyleObject }) => {
  const [_css, $theme] = useStyletron();
  return (
    <StyledTable
      $style={{
        marginBottom: $theme.sizing.scale600,
        boxSize: "border-box",
        borderRadius: $theme.borders.radius200,
        borderCollapse: "separate",
        ...expandBorderTop($theme.borders.border300),
        ...expandBorderLeft($theme.borders.border300),
        ...expandBorderRight($theme.borders.border300),
        // make wide tables horizontally scroll
        overflow: "hidden",
        width: "100%",
        display: "block",
        overflowX: "auto",
        ...props.$style,
      }}
      data-docscontent="true"
    >
      {props.children}
    </StyledTable>
  );
};

export const THead = styled("thead", { display: "table", width: "100%" });
export const TBody = styled("tbody", { display: "table", width: "100%" });
