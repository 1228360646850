import { StyledSvg } from "baseui/icon";
import React from "react";

export const SourceControlDocsIcon = (props: React.ComponentProps<typeof StyledSvg>) => {
  return (
    <StyledSvg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27 10C22.9167 10 19.4743 11.6393 17.2578 14.1328C15.0413 16.6264 14 19.8333 14 23C14 26.1667 15.0413 29.3736 17.2578 31.8672C18.8775 33.6893 21.2994 34.7874 24 35.3906V36V39V60.6094C21.2994 61.2126 18.8775 62.3107 17.2578 64.1328C15.0413 66.6264 14 69.8333 14 73C14 76.1667 15.0413 79.3736 17.2578 81.8672C19.4743 84.3607 22.9167 86 27 86C34.1442 86 40 80.1442 40 73C40 66.9174 35.6707 62.0052 30 60.6094V50.957C32.5133 52.8564 35.626 54 39 54H56.332C56.7924 56.1275 57.7447 58.1649 59.2578 59.8672C61.4743 62.3607 64.9167 64 69 64C76.1442 64 82 58.1442 82 51C82 43.8558 76.1442 38 69 38C64.9167 38 61.4743 39.6393 59.2578 42.1328C57.7447 43.8351 56.7924 45.8725 56.332 48H39C33.993 48 30 44.007 30 39V36V35.3906C35.6707 33.9948 40 29.0826 40 23C40 15.8558 34.1442 10 27 10ZM27 16C30.9015 16 34 19.0985 34 23C34 26.8403 30.9867 29.8696 27.1719 29.9648C27.0535 29.9578 26.9348 29.9578 26.8164 29.9648C24.3649 29.9168 22.8267 29.1029 21.7422 27.8828C20.6253 26.6264 20 24.8333 20 23C20 21.1667 20.6253 19.3736 21.7422 18.1172C22.859 16.8607 24.4167 16 27 16ZM69 44C72.9015 44 76 47.0985 76 51C76 54.9015 72.9015 58 69 58C66.4167 58 64.859 57.1393 63.7422 55.8828C62.6871 54.6958 62.0884 53.0271 62.0234 51.3008C62.0454 51.0983 62.0467 50.8941 62.0273 50.6914C62.0939 48.968 62.6888 47.3023 63.7422 46.1172C64.859 44.8607 66.4167 44 69 44ZM26.8164 66.0352C26.9348 66.0422 27.0535 66.0422 27.1719 66.0352C30.9867 66.1304 34 69.1597 34 73C34 76.9015 30.9015 80 27 80C24.4167 80 22.859 79.1393 21.7422 77.8828C20.6253 76.6264 20 74.8333 20 73C20 71.1667 20.6253 69.3736 21.7422 68.1172C22.8267 66.8971 24.3649 66.0832 26.8164 66.0352Z"
        fill="#545454"
      />
    </StyledSvg>
  );
};
