import { StyledSvg } from "baseui/icon";
import React from "react";

export const BookIcon = (props: React.ComponentProps<typeof StyledSvg>) => {
  return (
    <StyledSvg viewBox="0 0 16 16" {...props}>
      <path d="M4.16677 1.33398C3.34427 1.33398 2.66677 2.01148 2.66677 2.83398V12.4186C2.65795 12.4723 2.65795 12.5271 2.66677 12.5807V13.1673C2.66677 13.9898 3.34427 14.6673 4.16677 14.6673H12.8334C12.8997 14.6683 12.9655 14.656 13.027 14.6313C13.0884 14.6066 13.1444 14.5699 13.1916 14.5234C13.2388 14.4769 13.2762 14.4214 13.3018 14.3603C13.3274 14.2992 13.3406 14.2336 13.3406 14.1673C13.3406 14.1011 13.3274 14.0355 13.3018 13.9743C13.2762 13.9132 13.2388 13.8578 13.1916 13.8112C13.1444 13.7647 13.0884 13.728 13.027 13.7033C12.9655 13.6786 12.8997 13.6664 12.8334 13.6673H4.16677C3.8846 13.6673 3.66677 13.4495 3.66677 13.1673V13.0007H12.8334C12.966 13.0006 13.0932 12.948 13.187 12.8542C13.2807 12.7604 13.3334 12.6333 13.3334 12.5007V2.83398C13.3334 2.01148 12.6559 1.33398 11.8334 1.33398H4.16677ZM4.16677 2.33398H11.8334C12.1156 2.33398 12.3334 2.55182 12.3334 2.83398V12.0007H3.66677V2.83398C3.66677 2.55182 3.8846 2.33398 4.16677 2.33398ZM5.83344 4.66732C5.76718 4.66638 5.7014 4.67862 5.63991 4.70333C5.57843 4.72803 5.52247 4.76472 5.47528 4.81124C5.4281 4.85776 5.39063 4.9132 5.36506 4.97432C5.33948 5.03545 5.32631 5.10105 5.32631 5.16732C5.32631 5.23358 5.33948 5.29918 5.36506 5.36031C5.39063 5.42144 5.4281 5.47687 5.47528 5.5234C5.52247 5.56992 5.57843 5.6066 5.63991 5.63131C5.7014 5.65601 5.76718 5.66825 5.83344 5.66732H10.1668C10.233 5.66825 10.2988 5.65601 10.3603 5.63131C10.4218 5.6066 10.4777 5.56992 10.5249 5.5234C10.5721 5.47687 10.6096 5.42144 10.6351 5.36031C10.6607 5.29918 10.6739 5.23358 10.6739 5.16732C10.6739 5.10105 10.6607 5.03545 10.6351 4.97432C10.6096 4.9132 10.5721 4.85776 10.5249 4.81124C10.4777 4.76472 10.4218 4.72803 10.3603 4.70333C10.2988 4.67862 10.233 4.66638 10.1668 4.66732H5.83344Z" />
    </StyledSvg>
  );
};

export const BookFilledIcon = (props: React.ComponentProps<typeof StyledSvg>) => {
  return (
    <StyledSvg viewBox="0 0 16 16" {...props}>
      <path d="M12.8332 13.0007C13.1092 13.0007 13.3332 12.777 13.3332 12.5007V2.83398C13.3332 2.00698 12.6602 1.33398 11.8332 1.33398H4.1665C3.3395 1.33398 2.6665 2.00698 2.6665 2.83398V13.1673C2.6665 13.9943 3.3395 14.6673 4.1665 14.6673H12.8332C13.1092 14.6673 13.3332 14.4437 13.3332 14.1673C13.3332 13.891 13.1092 13.6673 12.8332 13.6673H4.1665C3.89084 13.6673 3.6665 13.443 3.6665 13.1673V13.0007H12.8332ZM5.83317 4.66732H10.1665C10.4425 4.66732 10.6665 4.89098 10.6665 5.16732C10.6665 5.44365 10.4425 5.66732 10.1665 5.66732H5.83317C5.55717 5.66732 5.33317 5.44365 5.33317 5.16732C5.33317 4.89098 5.55717 4.66732 5.83317 4.66732Z" />
    </StyledSvg>
  );
};
