import { StyledSvg } from "baseui/icon";
import React from "react";

export const TypeScriptIcon = (props: React.ComponentProps<typeof StyledSvg>) => (
  <StyledSvg viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.10059 4.97583V3.30005H8.28093V4.97583H5.5292L5.49993 12.6743H3.84679V4.97583H1.10059ZM10.0716 10.0884C9.99891 10.0031 9.9248 9.91614 9.84559 9.83077C9.47639 10.0463 8.7437 10.4858 8.42928 10.6744C8.33682 10.7299 8.28052 10.7637 8.28027 10.7637C8.57191 11.2646 9.04691 11.8402 9.55672 12.1379C10.6387 12.7703 11.7904 12.8399 12.9685 12.5344C15.1237 11.9755 15.3109 9.61978 14.3698 8.38863C13.9975 7.90202 13.4794 7.60155 12.9381 7.33974C12.763 7.255 12.5869 7.17209 12.4108 7.08918C12.0315 6.91054 11.6521 6.73191 11.2828 6.53499C10.8287 6.29252 10.684 5.88987 10.8508 5.4828C10.9955 5.12986 11.4192 4.95642 11.8754 5.04203C12.2383 5.11052 12.6426 5.4955 12.8403 5.79873C12.8403 5.79873 13.9445 5.08843 14.3394 4.80287C14.3394 4.80287 13.8042 3.30273 11.4236 3.30273C10.7 3.32151 10.0765 3.56454 9.56003 4.06772C8.61057 4.99288 8.60836 6.80011 9.63294 7.72251C9.9874 8.04153 10.4212 8.27976 10.8459 8.51298C10.8845 8.53415 10.923 8.55528 10.9613 8.57642C11.2456 8.73336 11.5394 8.87257 11.8333 9.01179C12.0711 9.12442 12.3088 9.23705 12.5415 9.35908C12.8116 9.50103 12.9608 9.75455 12.9508 10.0639C12.9398 10.414 12.7531 10.6747 12.4333 10.8095C11.7683 11.0901 11.1242 11.0144 10.5548 10.5814C10.376 10.4455 10.227 10.2707 10.0716 10.0884Z"
        fill="#0F60A6"
      />
    </svg>
  </StyledSvg>
);
