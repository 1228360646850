import { expandBorderRadii, paddingUtil } from "@gadgetinc/widgets";
import { useStyletron } from "baseui";
import { LabelLarge, ParagraphXSmall } from "baseui/typography";
import { compact } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef } from "react";
import { SeparateChildren } from "web/src/components/edit/shared/SeparateChildren";
import type { DocsSearchState, SearchResultHit } from "./DocsSearchState";

export const SearchResult = observer((props: { state: DocsSearchState; result: SearchResultHit }) => {
  const [css, $theme] = useStyletron();
  const ref = useRef<HTMLDivElement>(null);
  const highlights = {
    ...props.result.hit._highlightResult,
    ...props.result.hit._snippetResult,
  };

  const isActive = props.state.activeResult == props.result;

  const categories = compact([
    highlights.category2,
    highlights.category3,
    highlights.category4,
    highlights.category5,
    highlights.category6,
  ]).map((highlight) => highlight.value);
  const title = categories.pop() ?? highlights.category1?.value;

  useEffect(() => {
    if (isActive && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [isActive]);

  return (
    <div
      ref={ref}
      className={css({
        ...paddingUtil($theme.sizing.scale300, $theme.sizing.scale500),
        cursor: "pointer",
        ...expandBorderRadii($theme.borders.radius200),
        backgroundColor: isActive ? $theme.colors.primary100 : "transparent",
      })}
      onMouseEnter={() => props.state.setActivePosition(props.result.position)}
      tabIndex={0}
    >
      <LabelLarge>
        <span dangerouslySetInnerHTML={{ __html: title ?? "" }} />
      </LabelLarge>
      {highlights.category2 && (
        <ParagraphXSmall className={css({ marginBottom: $theme.sizing.scale200, color: $theme.colors.contentSecondary })}>
          <SeparateChildren separator=" > ">
            {categories.map((content, index) => (
              <span key={index} dangerouslySetInnerHTML={{ __html: content }} />
            ))}
          </SeparateChildren>
        </ParagraphXSmall>
      )}
      {highlights.content && (
        <ParagraphXSmall>
          <span dangerouslySetInnerHTML={{ __html: highlights.content.value }} />
        </ParagraphXSmall>
      )}
    </div>
  );
});
