import type { GadgetFlags } from "state-trees/src/GadgetFlags";
import type { DocsUser } from "../chrome/DocsContext";
import type { DocsArea } from "../chrome/DocsInterface";
import type { FullMetaBlob, ShortMetaBlob } from "../chrome/FullMetaBlob";

export const shouldShowVersionSelector = (
  currentArea: DocsArea,
  availableApps: ShortMetaBlob[],
  flags: GadgetFlags,
  currentApp: FullMetaBlob,
  user?: DocsUser
) => {
  if (currentArea !== "guides") {
    return false;
  }

  return user?.isStaff || currentApp.multiEnvironmentEnabled;
};

export const shouldShowEnvironmentSelector = (
  currentArea: DocsArea,
  availableApps: ShortMetaBlob[],
  flags: GadgetFlags,
  currentApp: FullMetaBlob,
  user?: DocsUser
) => {
  if (currentArea !== "api") {
    return false;
  }

  return currentApp.multiEnvironmentEnabled;
};
