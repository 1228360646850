import { comparer, computed, makeObservable } from "mobx";
import path from "path";
import type { ModelIndexEntry, SourceFileIndexEntry } from "./IndexEntry";
import { memoize } from "./Memoizable";
import { schemaMetadataFileName } from "./database/schemaMetadataFileName";
import {
  fileIsGlobalAction,
  fileIsNamespacedModelSchemaMetadata,
  fileIsNonNamespacedModelSchemaMetadata,
  isExecutableJsOrTsFile,
} from "./file-based-actions/filePathUtils";

export class FileSetCache {
  memos: Record<string, any> = {};

  constructor(
    readonly files: () => SourceFileIndexEntry[],
    readonly canHaveNamespacedModels: () => boolean,
    readonly memoize: boolean,
    readonly modelsWithoutProblemsData: () => ModelIndexEntry[]
  ) {
    makeObservable(this);
  }

  @memoize
  @computed({ equals: comparer.shallow })
  private get schemaFiles() {
    return this.files().filter((file) =>
      this.canHaveNamespacedModels() ? fileIsNamespacedModelSchemaMetadata(file) : fileIsNonNamespacedModelSchemaMetadata(file)
    );
  }

  @memoize
  @computed({ equals: comparer.shallow })
  get schemaFilePaths() {
    return Array.from(
      new Set([...this.schemaFiles.map((file) => file.path), ...this.modelsWithoutProblemsData().map((model) => model.schemaFilePath)])
    );
  }

  @memoize
  @computed({ equals: comparer.shallow })
  get modelActionFilePaths() {
    const allModelActionFolders = this.schemaFilePaths.map((schemaFilePath) =>
      schemaFilePath.replace(`/${schemaMetadataFileName}`, "/actions")
    );

    return this.files()
      .filter((file) => isExecutableJsOrTsFile(file.path) && allModelActionFolders.includes(path.dirname(file.path)))
      .map((file) => file.path);
  }

  @memoize
  @computed({ equals: comparer.shallow })
  get globalActionFiles() {
    return this.files().filter((file) => fileIsGlobalAction(file, this.canHaveNamespacedModels()));
  }

  @memoize
  @computed({ equals: comparer.shallow })
  get globalActionFilePaths() {
    return this.globalActionFiles.map((file) => file.path);
  }

  @memoize
  @computed({ equals: comparer.shallow })
  get allActionFilePaths() {
    return [...this.globalActionFilePaths, ...this.modelActionFilePaths];
  }
}
