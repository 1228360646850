import { useStyletron } from "baseui";
import type { DetailedHTMLProps, HTMLAttributes, ReactNode } from "react";
import React, { Children, Fragment } from "react";

/** Output all the child react nodes with a separator between them */
export const SeparateChildren = (props: {
  children: ReactNode[];
  separator?: ReactNode;
  childProps?: DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;
}) => {
  const [css, $theme] = useStyletron();
  const { children, separator = ", ", childProps = { className: css({ ...$theme.typography.font100 }) } } = props;

  return (
    <>
      {Children.map(children, (item, index) => (
        <Fragment key={index}>
          {index > 0 && separator}
          <span {...childProps}>{item}</span>
        </Fragment>
      ))}
    </>
  );
};
